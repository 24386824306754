import React from 'react';
import './App.css';
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import DashboardPage from './pages/DashboardPage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import AsideBarComponent from './components/AsideBarComponent';
import TopbarComponent from './components/TopbarComponent';
import FilterComponent from './components/FilterComponent';
import TopbarLandingPage from './components/TopbarLandingPage';
import LandingPage from './pages/LandingPage'
import ErrorPage from './pages/404Page';
import axios from 'axios';
import Login from "./pages/LoginPage"
import DrilldownPage from './pages/DrilldownPage';
import { API__LIVE_Link } from './commons/Constant';
import IndicatorDashboardPage from './pages/IndicatorDashboardPage';
import AdminPage from './pages/AdminPage'
import ExampleCirclePiePage from './pages/ExampleCirclePiePage';
import ActionDashboardPage from './pages/ActionDashboardPage';
import useApiCall from './customhooks/api/useapicall';
import { FilterContext } from './FilterContext';


function App() {
  const [option, setOption] = useState({});
  // const [data, setData] = useState([]);
  const [dashboardid, setDashboardid] = useState('')
  const [currentUrl, setCurrentUrl] = useState('');
  const [titlePage, setTitlePage] = useState('Family Planning');
  const [indOptions, setIndOptions] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn'));


  const { data, error, loading } = useApiCall(`${API__LIVE_Link}get_dashboard_list.php`);

  const handleButtonClick = () => {
    alert('Button clicked in ParentComponent!');
  };

  const handleLogin = () => {
    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
    window.dispatchEvent(new Event('userLoggedIn'));
  };

  // Function to handle logout
  const handleLogout = () => {
    localStorage.clear(); // Clear the entire localStorage
    window.dispatchEvent(new Event('userLoggedIn'));
    setIsLoggedIn(false);
  };

  const fetchData = () => {
    if (!isDataFetched) {
      getoptiondata();
      AOS.init();
      setIndOptions([]);
      const setDefaultColor = () => {
        const idsColor = localStorage.getItem('ids_color');
        if (!idsColor) {
          localStorage.setItem('ids_color', 'primary-color');
        }
      };
      setDefaultColor();
      setIsDataFetched(true);
    }
  };
  useEffect(() => {
    const storedDashboardId = localStorage.getItem('dashboardid');
    setDashboardid(storedDashboardId || '1');
    fetchData();
  }, [data, dashboardid]);

  useEffect(() => {
    sessionStorage.setItem('url', currentUrl);
  }, [currentUrl]);


  const getoptiondata = () => {

    axios.post(`${API__LIVE_Link}get_topbar_options.php`)
      .then(response => {
        // Handle successful response
        if (response.data) {
          setOption(response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        console.log("Error Display");
      });
  }

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn === 'true') {
      setIsLoggedIn(true);
    }
    if (storedIsLoggedIn === 'false') {
      setIsLoggedIn(false);
    }
  }, []);

  // const getdashboarddata = () => {
  //   axios.post(`${API__LIVE_Link}get_dashboard_list.php`)
  //     .then(response => {
  //       if (response.data) {
  //         setData(response.data)

  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //       console.log("Error Display");
  //     });
  // }

  const optionMappingToOptions = {};
  data.forEach(item => {
    if (item.topbar_dropdown_optionname) {
      optionMappingToOptions[item.topbar_dropdown_optionname] = item.topbar_dropdown_optionname;
    }
  });
  const handleUrlChange = (url) => {
    setCurrentUrl(url);
    const matchedData = data.find(item => url === item.url_prefix);
    if (matchedData) {
      setTitlePage(matchedData.page);
      const options = optionMappingToOptions[matchedData.topbar_dropdown_optionname];
      setIndOptions(option[options]);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  //

  const [dateContext, setDateContext] = useState("");

  return (
    <HashRouter hashType="noslash">
      <FilterContext.Provider value={{ dateContext, setDateContext }}>
        <div className="app main-wrapper primary-color" id="main-body">
          <FilterComponent onUrlChange={handleUrlChange} isActive={isActive} toggleClass={toggleClass} />
          {data ? (
            /^\/inddashboard\/\w+$/.test(currentUrl) || /^\/drilldown\/\w+$/.test(currentUrl) || currentUrl === "/login" || currentUrl == "/Admin" ? '' :
              <AsideBarComponent data={data} currentUrl={currentUrl} onLogout={handleLogout} alertFun={handleButtonClick} />
          ) : (
            <p>Loading data...</p>
          )}
          <div className='body-container container-fluid'>
            {
              /^\/drilldown\/\w+$/.test(currentUrl) || currentUrl === "/login" ? (
                ''
              ) : /^\/inddashboard\/\w+$/.test(currentUrl) ? (
                <TopbarLandingPage isActive={isActive} toggleClass={toggleClass} url={currentUrl} />
              ) : currentUrl === '/landingPage' ? (
                <TopbarLandingPage isActive={isActive} toggleClass={toggleClass} pagetitle={'Integrated Dashboard Sindh.'} />
              ) : currentUrl === '/actiondashboard' ? (
                <TopbarLandingPage isActive={isActive} toggleClass={toggleClass} pagetitle='Action Dashboard' />
              ) : (
                <TopbarComponent isActive={isActive} toggleClass={toggleClass} pageTitle={titlePage} selectOptions={indOptions} />
              )
            }
            <div className='content'>
              <Routes>
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
                <Route path="/example" element={<ExampleCirclePiePage />} />

                {isLoggedIn ? (
                  <>
                    {data.map(item => (
                      <>
                        {item.id == 8 ? (
                          <Route path="/actiondashboard" element={<ActionDashboardPage onUrlChange={handleUrlChange} dashboard_id={item.id} />} />
                        ) : (
                          <Route
                            key={item.id}
                            path={`/${data.length > 0 ? item.url_prefix : ''}`}
                            element={data.length > 0 ? (
                              item.page_component === 'LandingPage' ? (
                                <LandingPage onUrlChange={handleUrlChange} pageTitle={item.description} dashboard_id={item.id} />
                              ) : (
                                <DashboardPage onUrlChange={handleUrlChange} pageTitle={item.description} dashboard_id={item.id} />
                              )
                            ) : null}
                          />
                        )}
                      </>
                      // <>
                      //   {item.id == 8 ? <Route path="/actiondashboard" element={<ActionDashboardPage onUrlChange={handleUrlChange} dashboard_id={item.id} />} /> : <Route
                      //     key={item.id}
                      //     path={`/${data.length > 0 ? item
                      //       .url_prefix : ''}`}
                      //     element={data.length > 0 ? <DashboardPage onUrlChange={handleUrlChange} pageTitle={item.description} dashboard_id={item.id} /> : null}
                      //   />}
                      // </>
                    ))}

                    {/* <Route path="/actiondashboard" element={<ActionDashboardPage onUrlChange={handleUrlChange} dashboard_id={7} />} /> */}
                    <Route path="/Admin" element={<AdminPage data={data} />} />
                    <Route path="*" element={<ErrorPage />} />
                    <Route path="/drilldown/:key" element={<DrilldownPage />} />
                    {/* <Route exact path="/landingPage" element={<LandingPage onUrlChange={handleUrlChange} dashboard_id={data[0]?.id} />} /> */}
                    {/* <Route exact path="/landingPage" element={<LandingPage onUrlChange={handleUrlChange} dashboard_id={data[0]?.id} />} /> */}
                    <Route path="/inddashboard/:key" element={<IndicatorDashboardPage />} />
                    <Route path="/landingpage2" element={<LandingPage onUrlChange={handleUrlChange} />} />
                  </>
                ) : (<Route path="/*" element={<Navigate to="/login" />} />)}
              </Routes>
            </div>
          </div>
        </div>
      </FilterContext.Provider>
    </HashRouter>
  );
}

export default App;
