import React,{useState, useEffect} from 'react'
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Circle4Component = ({percent, colors}) => {
    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setPercentage(percent);
    }, [percent]);

    return (
        <div style={{ "display": "flex", "justifyContent": "center" }}>
            <div style={{ width: "70px" }}>

                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        textColor: colors,
                        pathColor: colors,
                        trailColor: "lightgray"
                    })}
                />
            </div>
        </div>
    )
}

export default Circle4Component