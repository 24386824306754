import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';

drilldown(Highcharts);

const DrilldownPage = () => {
    const { key } = useParams();
console.log('Key:', key);

// Log the entire localStorage object
console.log('localStorage:', localStorage);
window.addEventListener('userLoggedIn', () => {
    localStorage.setItem('isLoggedIn', 'true');
  });
const storedData = localStorage.getItem(key);
console.log('Stored Data:', storedData);

const data = storedData ? JSON.parse(storedData) : null;
console.log('Parsed Data:', data);
    const drilldownChartOptions = {
        chart: {
            type: 'column',
        },
        title: {
            text: 'Drilldown Chart',
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            title: {
                text: 'Value',
            },
        },
        series: [
            {
                name: 'Drilldown Data',
                data: data,
            },
        ],
    };

    return (
        <div>
            <h1>Drilldown Page</h1>
            {data ? (
                <HighchartsReact highcharts={Highcharts} options={drilldownChartOptions} />
            ) : (
                <p>Loading drilldown data...</p>
            )}
        </div>
    );
};

export default DrilldownPage;
