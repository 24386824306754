import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className="error-container">
      {/* <h1 className="error-heading">404 - Page Not Found</h1>
      <p className="error-message">Oops! The page you are looking for does not exist.</p>
      <button className="error-button">
        <Link to="/" className="text-light text-decoration-none">Go Back to Home</Link>
       </button> */}

      <div className="lotti-animation">
        <lottie-player src="https://lottie.host/673899cd-db41-439b-870d-eb6669c64790/5Hs91BmlQl.json" background="transparent" speed="1" loop autoplay></lottie-player>
        <p className="small fst-italic"> Our dashboard is currently being polished to be presentable. <strong className='text-primary'>Thank you for your patience!</strong></p>
      </div>


    </div>
  );
};

export default ErrorPage;
