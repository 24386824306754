import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HCMap from 'highcharts/modules/map'; // Import the map module
import HighchartsMore from 'highcharts/highcharts-more'; // Import the highcharts-more module for pie charts

HCMap(Highcharts); // Initialize the map module
HighchartsMore(Highcharts); // Initialize the highcharts-more module

const PieChartsSmallComponent2 = () => {
    useEffect(() => {
        const fetchMapData = async () => {
            const topology = await fetch(
                'https://code.highcharts.com/mapdata/countries/pk/pk-all.topo.json'
            ).then((response) => response.json());

            // Prepare demo data
            const data = [
                ['pk-nw', 14],
                ['pk-ta', 15],
                ['pk-is', 16],
                ['pk-pb', 17],
            ];

            // Create the chart
            Highcharts.chart('container-pie2', {
                chart: {
                    type: 'pie', // Set the chart type to pie
                    backgroundColor: 'transparent', // Set the background color to transparent
                    spacing: [0, 0, 0, 0], // Remove spacing around the chart
                    margin: 0, // Remove margin around the chart
                    height: "210px",
                },
                title: {
                    text: 'Highcharts Pie Chart',

                },
                subtitle: {
                    text:
                        'Source map: <a href="http://code.highcharts.com/mapdata/countries/pk/pk-all.topo.json">Pakistan</a>',

                },
                plotOptions: {
                    pie: {
                        innerSize: '60%', // Set the inner size of the pie chart to 80%
                    },
                },
                series: [
                    {
                        data: data,
                        name: 'Random data',
                        states: {
                            hover: {
                                color: '#BADA55',
                            },
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                        },
                    },
                ],
            });
        };

        fetchMapData();
    }, []);

    return <div id="container-pie2"></div>;
};

export default PieChartsSmallComponent2