// LoginPage.js
import React, { useState } from 'react';
import LandingLoginComponent1 from '../components/LandingLoginComponent1';
import LandingLoginComponent2 from '../components/LandingLoginComponent2';


const LoginPage = ({ onLogin }) => {

  return (
    <>
      <LandingLoginComponent1 onLogin={onLogin} />
    </>
  );
};

export default LoginPage;
