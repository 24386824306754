import React from 'react'

const AboutUsComponent = () => {
    return (
        <section id="aboutus">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1 className="section-heading mb-5">
                            About System
                        </h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 small-p">
                            <p className="mt-3">
                                Integrated Public Health Dashboard is a powerful tool that redefines the way we approach
                                <strong>public health management and decision-making</strong>. Primary stakeholders of this
                                dashboard are
                                public health officials, healthcare providers, policymakers.
                                <p>
                                    One of the key features that
                                    sets this dashboard apart is the convenience of a single sign-in, which streamlines access
                                    to multiple data sources, enhancing efficiency and user experience. Integrated dashboard
                                    Identify, gather and consolidates data from a various sources, including government health
                                    facilities, hospitals, laboratories, and community health workers (i.e.,<strong className="">
                                        DHIS, LHWMIS,
                                        vLMIS, IDSRS, M&S, TB MIS, EPI MIS</strong> etc).
                                </p>
                            </p>
                            <p className="">
                                Integrated dashboard ensures that you have a 360-degree view of the public health landscape.
                                It provide <strong>meaningful visualizations (charts, graphs, maps)</strong> that make the
                                data easily
                                understandable. Examples include disease heat maps, trend charts, and demographic
                                breakdowns. This involve setting up of automated data feeds and APIs from the data sources.
                            </p>
                            <p>The dashboard provides policymakers with the tools to make informed choices, allocate
                                resources effectively, and respond swiftly to public health challenges. This will also help
                                in proactive Decision-Making like to identify potential outbreaks, trends, and areas that
                                require immediate attention, allowing you to take proactive measures.</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img src={require(`../assets/left-image.png`)} className="img-fluid mt-4 mb-3" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsComponent