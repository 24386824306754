import React from 'react';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import { HighchartsReact } from 'highcharts-react-official';

// Initialize the exporting module
HighchartsExporting(Highcharts);

const PieChartsSmallComponent1= ({data}) => {
    const newdata = parseFloat(data.replace('%', '')); // Parse the percentage value

    const chartOptions = {
        chart: {
            plotBackgroundColor: null,
            spacing: [0, 0, 0, 0], // Remove spacing around the chart
        margin: 0,
            plotShadow: false,
            type: 'pie',
            height: 70,
            width: 70
        },
        title: {
            text: '',
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        legend: {
            enabled: false, // Hide the legend
        },
        exporting: {
            enabled: false
          },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                },
            }
        },
        tooltip: {
            enabled: false, // Hide tooltip
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: 'Chrome',
                y: newdata,
                selected: true,
                dataLabels: {
                    enabled: false,
                }
            }, {
                name: 'Empty',
                y: 100 - newdata,
                selected: true,
                dataLabels: {
                    enabled: false,
                }
            }]
        }]
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
};

export default PieChartsSmallComponent1;
