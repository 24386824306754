import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Select from 'react-select';

const TopbarSelectComponent = ({ myOptions, defaultValue }) => {

    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);
    const [moptions, setMoptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const location = useLocation();
    const currentUrl = location.pathname;
    
    useEffect(() => {
        setSelectedOption(null);
    }, [currentUrl]);

    useEffect(() => {
        // Set the default selected option when defaultValue prop changes
        setSelectedOption(defaultValue);
    }, [defaultValue]);

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        sessionStorage.setItem('option', selectedOption.value);
    };

    return (
        <>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name="color"
                value={selectedOption}
                options={myOptions}
                onChange={handleSelectChange}
            />
        </>
    );
};

export default TopbarSelectComponent;
