import React, { Suspense } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import sunburst from 'highcharts/modules/sunburst'; // Import the Sunburst module

sunburst(Highcharts)

const SunburstChartComponent = ({ data, chartType, graphTitle, graphSubTitle, graphData, height, graphWaiting }) => {

  const heightValue = Number(height);
  const extraPixels = heightValue === 2 ? 5 : heightValue === 3 ? 10 : 0;
  const calculatedHeight = height * 240 + extraPixels;
  const options = {
    chart: {
      height:calculatedHeight + 'px'
    },
    colors: ['transparent', ...Highcharts.getOptions().colors],
    title: {
      text: graphTitle
    },
    subtitle: {
      text: graphSubTitle
    },
    series: [
      {
        type: "sunburst",
        data: data,
        name: 'Root',
        allowDrillToNode: true,
        borderRadius: 3,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
          filter: {
            property: 'innerArcLength',
            operator: '>',
            value: 16
          }
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 64
              }
            }
          },
          {
            level: 2,
            colorByPoint: true
          },
          {
            level: 3,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 4,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          }
        ]
      }
    ],
    tooltip: {
      headerFormat: '',
      pointFormat: 'The population of <b>{point.name}</b> is <b>{point.value}</b>'
    }
  };

  // return <HighchartsReact highcharts={Highcharts} options={options} />;
  return (
    // <>
    //   {graphWaiting ? (
    //     <div className="loader"></div>
    //   ) : (
    //     data ? (
    //       <HighchartsReact highcharts={Highcharts} options={options} />
    //     ) : (
    //       <div className="loader"></div>
    //     )
    //   )}
    // </>
    <div>
      <Suspense fallback={<div>Loading data...</div>}>

        {data ? (
          <div className="text-center mb-0">
            <strong>
              {graphWaiting ? <div className="loader"></div> : <HighchartsReact highcharts={Highcharts} options={options} />}
            </strong>
          </div>
        ) : (
          <div className="loader"></div> // Display loader or message when graphData is null
        )}
      </Suspense>
    </div>

  )

};

export default SunburstChartComponent;
