import React, { useEffect, useState, useRef } from 'react'
import TabsComponent from './TabsComponent';
import axios from 'axios'
import { API__LIVE_Link } from '../commons/Constant'
import RowChartComponent from './RowChartComponent'
import TableComponentJquery1 from './tableComponent/TableComponentJquery1';
import useDefaultMonthYear from '../feature/useDefaultMonthYear';
import SingleRowChartComponent from './SingleRowChartComponent';
import TabsTableComponent from './TabsTableComponent';

const DynamicRowChartComponent = ({ dataObject, tabdata }) => {
    const [GraphWaiting, setGraphWaiting] = useState(false)
    const [sourceData, setSourceData] = useState([])
    const [selectedIcon, setSelectedIcon] = useState('graph-icon');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [increment, setIncrement] = useState(0);
    const filterValue = sessionStorage.getItem('filter');
    const filterChangeCountRef = useRef(0);
    const [isPercent, setIsPercent] = useState('0');
    const distcode = sessionStorage.getItem('distcode');
    const procode = sessionStorage.getItem('province')

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    console.log('dynamicRowChartComponent -----')
    const { year, formattedMonth } = useDefaultMonthYear();

    const setSourceData1 = async () => {
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth';
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;

        const updatedSources = [];

        if (dataObject?.source_data?.length > 0) {
            for (const sourceData of dataObject.source_data) {
                try {
                    setGraphWaiting(true);
                    const response = await axios.get(`${API__LIVE_Link}${sourceData.api_link}${distQueryParam}${provQueryParam}&${option}=${value}`);

                    if (response.data) {
                        sourceData.api_link.includes("multi") ?
                            sourceData.data = response?.data : sourceData.data = response?.data;
                        setIsPercent(response.data.is_percent);

                        setGraphWaiting(false);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                } finally {
                    setGraphWaiting(false);
                }

                updatedSources.push(sourceData);
            }
            setSourceData({ source_data: updatedSources });
        }

    };


    const resetSourceData = () => {
        const updatedSources = dataObject?.source_data?.map(sourceData => {
            sourceData.data = [];
            return sourceData;
        });

        setSourceData({ source_data: updatedSources });

    };

    // useEffect(() => {
    //     setSourceData1();
    // }, [dataObject]);

    useEffect(() => {
        if (year !== null && formattedMonth !== null) {
            setSourceData1();
        }
    }, [dataObject]);

    useEffect(() => {
        // Check if this is the first change to filterValue
        if (filterChangeCountRef.current === 0) {
            // Increment the change count
            filterChangeCountRef.current++;
            return;
        }

        // If it's not the first change, run the effect
        resetSourceData();
        setSourceData1();
    }, [filterValue, procode, distcode, year, formattedMonth]);


    console.log('sourceData', sourceData)
    return (
        <div className='row mt-2 '>
            <div className='col-lg-12 mt-2'>
                <div className='box-heading'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                            <span
                                className={`icon-tab ${selectedIcon === 'graph-icon' ? 'active' : ''} graph-icon`}
                                onClick={() => setSelectedIcon('graph-icon')}
                            >
                                <i className="bi bi-bar-chart-line"></i>
                            </span>
                            <span
                                className={`icon-tab ${selectedIcon === 'table-icon' ? 'active' : ''} table-icon`}
                                onClick={() => setSelectedIcon('table-icon')}
                            >
                                <i className="bi bi-table"></i>
                            </span>
                            {/* <span
                                className={`icon-tab ${selectedIcon === 'map-icon' ? 'active' : ''} map-icon`}
                                onClick={() => setSelectedIcon('map-icon')}
                            >
                                <i className="bi bi-map"></i>
                            </span> */}
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 text-center text-dark order-sm-3 order-3 order-md-2'>
                            <strong>{dataObject.indicatorName}</strong>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6 text-end order-sm-2 order-2 order-md-3'>
                            {/* <span className='icon-tab excel-icon'>
                                <i class="bi bi-file-earmark-excel"></i>
                            </span>
                            <span className='icon-tab pdf-icon' onClick={handleGeneratePDF}>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </span> */}
                            <span className='icon-tab collapse-icon' onClick={toggleCollapse}>
                                <i className={isCollapsed ? 'bi bi-arrow-down-short' : 'bi bi-arrow-up-short'}></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {!isCollapsed && (
                <>

                    {dataObject.columns.map((column, mainindex) => {
                        // Check if column.classes contains 'tabbed'
                        const isTabbed = column.classes.split(' ').includes('tabbed');

                        // Collect items into an array if 'tabbed' is present
                        const tabbedItems = isTabbed ? [] : null;

                        return (
                            <div key={mainindex} className={`col-lg-${column.div_width} ${column.classes}`}>
                                <div className='row'>
                                    {sourceData?.source_data?.map((item, index) => {
                                        // Check if item belongs to the current column
                                        const belongsToColumn = (mainindex + 1) === +item.component_design_column;

                                        if (belongsToColumn && isTabbed) {
                                            // If 'tabbed' is in column.classes, collect items
                                            tabbedItems.push(item);
                                        }

                                        return belongsToColumn && !isTabbed ? (
                                            selectedIcon === 'graph-icon' ? (
                                                <React.Fragment key={index}>
                                                    <SingleRowChartComponent
                                                        sourceData={item}
                                                        GraphWaiting={GraphWaiting}
                                                        dataObject={dataObject}
                                                        idPrefix={dataObject.idPrefix}
                                                        data={tabdata}
                                                    />
                                                </React.Fragment>
                                            ) : selectedIcon === 'table-icon' ? (
                                                <React.Fragment key={index}>
                                                    <TableComponentJquery1
                                                        sourceData={item}
                                                        GraphWaiting={GraphWaiting}
                                                        dataObject={dataObject}
                                                        idPrefix={dataObject.id}
                                                        pre_index={index}
                                                        mainindex={mainindex}
                                                        title={dataObject.title}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <div key={index}>Map</div>
                                            )
                                        ) : null;
                                    })}

                                    {/* {isTabbed && tabbedItems.length > 0 && (
                                        <TabsComponent idPrefix={dataObject.idPrefix} data={tabbedItems} columnNumber={(mainindex+1)} />
                                    )} */

                                    }

                                    {isTabbed && tabbedItems.length > 0 && (
                                        selectedIcon === 'graph-icon' ? (
                                            <TabsComponent idPrefix={dataObject.idPrefix} data={tabbedItems} columnNumber={mainindex + 1} type="graph" />
                                        ) : selectedIcon === 'table-icon' ? (
                                            <TabsTableComponent idPrefix={dataObject.idPrefix} data={tabbedItems} columnNumber={mainindex + 1} type="table" />
                                        ) : null
                                    )}
                                </div>
                            </div>
                        );
                    })}


                    {/* <div className='col-lg-3' style={{ marginTop: "10px", marginBottom: "13px" }}>
                        {dataObject.source_data?.isloading ? (
                            <div className="loader"></div>
                        ) : (
                            <TabsComponent idPrefix={dataObject.idPrefix} data={tabdata} />
                        )}
                    </div> */}
                </>
            )}
        </div>
    )
}

export default DynamicRowChartComponent