import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SurveyChartComponent from './charts/SurveyChartComponent';
import SupervisionChartComponent from './charts/SupervisionChartComponent';
import axios from 'axios';
import SingleRowChartComponent from './SingleRowChartComponent';
import ChartComponentIndicator from './charts/ChartComponentIndicator';
import TableComponentJquery1 from './tableComponent/TableComponentJquery1';

const TabsTableComponent = ({ idPrefix, data, columnNumber }) => {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    console.log('tab component', data)


    return (

        <div className={data?.length > 1 ? "box-white h-100" : "box-white h-100"}>
            <ul className={data?.length > 1 ? "nav nav-pills pb-3 table-vertical-tab" : "nav nav-pills justify-content-around border-bottom pb-3"}>
                {data?.map((section, index) => (
                    <li className="nav-item" key={index}>
                        <button
                            className={`nav-link ${activeTab === `tab${index}` ? 'active' : ''}`}
                            onClick={() => handleTabChange(`tab${index}`)}
                        >
                            {section.title}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="tab-content mt-2" style={{ background: "lightgray", padding: "15px" }}>
                {data?.map((section, index) => (
                    <div className={`tab-pane ${activeTab === `tab${index}` ? 'active' : ''}`} key={index} id={`${columnNumber}${idPrefix}-tab${index}Content`}>
                        {/* Adding a console log to check if this block is being rendered */}
                        {console.log('Rendering chart for section:', section)}
                        {/* Check if activeTab is correct and if data[index] is defined */}
                        {activeTab === `tab${index}` && section &&
                            // <ChartComponentIndicator
                            //     text={'value'}
                            //     graphData={section.data}
                            //     idPrefix={`${columnNumber}${idPrefix}-section-${index}`}
                            //     graphTitle={section.title}
                            //     chartType={section.chart_type}
                            //     is_percent={section.is_percent}
                            //     series_type={section.series_type}

                            // />

                            
                            <TableComponentJquery1
                                sourceData={section.data}
                                GraphWaiting={section.GraphWaiting}
                                dataObject={section.data}
                                idPrefix={index + 1}
                                pre_index={index}
                                mainindex={index}
                                is_tabbed={true}
                            />

                        }
                    </div>
                ))}
            </div>
        </div>

    );
};

export default TabsTableComponent;
