import React from 'react'

const FooterSectionComponent = () => {
    return (
        // <section className='footerComponent'>
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-lg-12">
        //                 <div className="bottom-logos">
        //                     <img src={require(`../assets/logo.png`)} alt='' className='img-fluid' />
        //                     <img src={require(`../assets/ministry_logo/us-logo2.png`)} alt='' className='img-fluid' />

        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <footer>
            <div class="container">
                <div class="footer-div">
                    <img src={require(`../assets/logo.png`)} alt='' className='img-fluid' />
                    <img src={require(`../assets/ministry_logo/us-logo2.png`)} alt='' className='img-fluid' />
                </div>
            </div>
        </footer>
    )
}

export default FooterSectionComponent