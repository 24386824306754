import React, { useEffect, useState } from 'react';

const SmallCircleChart = ({ percent }) => {
    const [percentage, setPercentage] = useState(0);
  
    useEffect(() => {
      setPercentage(percent);
    }, [percent]);
  
    return (
      <div className="pie-title-center">
        <div
          className="pie-chart"
          style={{
            background: `conic-gradient(#49a9ff ${percentage}%, #b0acac ${percentage}% 100%)`,
            borderRadius: '50%',
            width: '55px',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            left: '5px',
            top: '2px'
          }}
        >
          <span className="pie-value">{Math.round(percentage)}%</span>
        </div>
      </div>
    );
  };

export default SmallCircleChart;
