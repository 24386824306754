import React, { Suspense, useEffect, useState } from 'react';
import Highcharts, { chart } from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import drilldown from 'highcharts/modules/drilldown';
HighchartsExporting(Highcharts);
drilldown(Highcharts);

const SingleSeriesChart = ({ landingpagecomponent, chartType, graphTitle, graphSubTitle, height, is_percent, series_type, graphData, drilldownData, graphWaiting, graphDatatest }) => {

    const [count, setCount] = useState(0)

    useEffect(() => {
        // setCount(count + 1)
        if(count === 0){
            setCount(prevCount => prevCount + 1);
        }
        console.log('singleSeriesChart Callled....', chartType)
        console.log('singleSeriesChart graphData....', graphData)

    }, 
    [graphData, count])
    
   
    
    let totalValue = 100;

    if (is_percent === '1') {

        totalValue = Math.max(...graphData?.map(item => item?.y));
        //console.log(is_percent,":",graphTitle,":",totalValue)
        // totalValue = graphData.reduce((sum, item) => sum + item.y, 0);
    }
    

    const heightValue = Number(height);
    const extraPixels = heightValue === 2 ? 5 : heightValue === 3 ? 10 : 0;
    const calculatedHeight = heightValue * 240 + extraPixels;

    const [selectedDrilldown, setSelectedDrilldown] = useState(null);
    const handleDrilldown = (drilldownId) => {
        const drilldownOptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: `Drilldown Chart for ${drilldownId}`
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: 'Value'
                }
            },
            series: [
                {
                    name: "Drilldown Data",
                    data: drilldownData[drilldownId]
                }
            ]
        };
        setSelectedDrilldown(drilldownOptions);
    };
 
    const options = {
        chart: {
            type: chartType,
            height: calculatedHeight + 'px'
        },
        title: {
            text: graphTitle,
        },
        subtitle: {
            text: graphSubTitle,
        },
        xAxis: {
            type: 'category',
            labels: {
                style: {
                    fontSize: '9px',
                    fontWeight: 'normal', 
                },
            },
        },
        yAxis: {
            title: {
                text: false,
            },
            max: is_percent === "1" ? totalValue > 100 ? totalValue : 100 : undefined,
            labels: {
                style: {
                    fontSize: '9px',
                    fontWeight: 'normal', 
                    textDecoration: 'none', 
                    color: '#000',
                },
            },
            plotLines: [{
                color: 'red',
                width: 1.5,
                value: is_percent === "1" ? 100 : undefined,
                zIndex: 5
            }],
        },

        exporting: {
            enabled: true 
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b><br>Value: ${this.point.y}`;
            }
        },

        series: landingpagecomponent
            ? graphData
            : series_type === "multi"
                ? graphData
                : [
                    {
                        showInLegend: false,
                        colorByPoint: true,
                        data: graphData?.map((item) => ({
                            name: item?.name,
                            y:
                                series_type === "single"
                                    ? is_percent === "1"
                                        ? item?.y
                                        : item?.y
                                    : item?.y,
                            drilldown: item?.drilldown,
                        })),
                    },
                ],
        // legend: {
        //     enabled: false // Enable the legend, but specific series will be hidden
        // },
        // series: graphData,
        // series: series_type === "multi" ? graphData : [
        //     {
        //         showInLegend: false,
        //         colorByPoint: true,
        //         data: graphData?.map(item => ({
        //             name: item?.name,
        //             y: series_type === 'single' ? is_percent === "1" ? item?.y : item?.y : item?.y,

        //             drilldown: item?.drilldown
        //         })),

        //     }
        // ],

        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true, // Set this to true to show data labels
                    format: '<b>{point.name}</b>: {point.percentage:.2f}%'
                },
            },
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () { 
                            if (this.drilldown) {
                                handleDrilldown(this.drilldown);
                            }
                        }
                    }
                }
            }
        },
        // drilldown: {
        //     series: series_type === 'single' ? graphData?.map(item => ({
        //         id: item?.drilldown,
        //         data: drilldownData === undefined ? [] : drilldownData[item?.drilldown]
        //     })) : []
        // }

        drilldown: landingpagecomponent !== true
            ? {
                series: series_type === 'single' ? graphData?.map(item => ({
                    id: item?.drilldown,
                    data: drilldownData === undefined ? [] : drilldownData[item?.drilldown]
                })) : []
            }
            : '',

    };
    return (
        <div>
            <Suspense fallback={<div>Loading data...</div>}>

                {graphData ? (
                    <div className="text-center mb-0">
                        <strong>
                            {graphWaiting ? <div className="loader"></div> : <HighchartsReact highcharts={Highcharts} options={options} />}
                        </strong>
                    </div>
                ) : (
                    <div className="loader"></div> // Display loader or message when graphData is null
                )}
                {selectedDrilldown && (
                    <div>
                        <h3>Drilldown Chart</h3>
                        <HighchartsReact highcharts={Highcharts} options={selectedDrilldown} />
                    </div>
                )}
            </Suspense>
        </div>
    );
};

export default SingleSeriesChart;
