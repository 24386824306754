import React from 'react';
import defaultIcon from '../assets/icons/menu/default.svg';

const DefaultIconComponent = ({ item }) => {
  let iconSrc;
  
  try {
    iconSrc = require(`../assets/icons/menu/${item.dashboard_icon}.svg`);
  } catch (err) {
    iconSrc = defaultIcon;
  }

  return <img src={iconSrc} alt="" />;
};

export default DefaultIconComponent
