import { faChild, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const Card4Component = ({ indicatorvalue, indicatortitle, source, subValue, cardWaiting, colors, numbers }) => {

    return (
        <div className='item card4component'>
            <div className="innerCard bg-light">
                <div className="d-flex w-100">
                    <div className='icon-div'>
                        <span className=''>
                            <FontAwesomeIcon icon={faChild} style={{ color: colors[numbers] }} />
                        </span>
                    </div>
                    <div className="figure-div text-center">
                        <h3 className='mb-0' style={{ color: colors[numbers] }}> <strong> {cardWaiting ? <FontAwesomeIcon icon={faSpinner} spin /> : indicatorvalue} </strong></h3>
                        <span className='align-self-end opacity-50'>{source}</span>
                        <div className="px-4 mb-2">
                            {/* <ProgressBar now={indicatorvalue.replace(/[%|,]/g, '')} className='bg-success' max={100} animated={true} style={{ height: '10px' }} /> */}
                            {indicatorvalue.includes('%') ? <div class="progress" style={{ height: "10px" }}>
                                <div class="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${indicatorvalue.replace(/[%|,]/g, '')}%`, backgroundColor: colors[numbers] }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div> : ""}
                        </div>
                    </div>
                </div>
                <table className='table table-card-dynamic'>
                    <tbody>
                        <tr>
                            {
                                subValue?.map(({ index, title, value }) => {
                                    return (
                                        <td key={index}>{title} <br /> {value}</td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Card4Component