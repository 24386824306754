import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import { API__LIVE_Link } from '../commons/Constant';
import RowChartComponent from './RowChartComponent';
import TableComponent from './TableComponent';
import TableComponentJquery1 from './tableComponent/TableComponentJquery1';
import useDefaultMonthYear from '../feature/useDefaultMonthYear';


const SingleRowComponent = ({ dataObject, tabdata }) => {

    const [GraphWaiting, setGraphWaiting] = useState(false)
    const [sourceData, setSourceData] = useState([])
    const [selectedIcon, setSelectedIcon] = useState('graph-icon');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [increment, setIncrement] = useState(0);
    const filterValue = sessionStorage.getItem('filter');
    const filterChangeCountRef = useRef(0);
    const [isPercent, setIsPercent] = useState('0');
    const distcode = sessionStorage.getItem('distcode');
    const procode = sessionStorage.getItem('province')
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };



    const { year, formattedMonth } = useDefaultMonthYear();




    const setSourceData1 = async () => {

        // const value = sessionStorage.getItem('filter') || '202306'
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth';
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;
        const updatedSources = [];

        for (const sourceData of dataObject.source_data) {
            try {
                setGraphWaiting(true);
                const response = await axios.get(`${API__LIVE_Link}${sourceData.api_link}${distQueryParam}${provQueryParam}&${option}=${value}&level=district`);

                if (response.data) {
                    sourceData.api_link.includes("multi") ?
                        sourceData.data = response?.data : sourceData.data = response?.data;
                    setGraphWaiting(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            } finally {
                setGraphWaiting(false);
            }

            updatedSources.push(sourceData);
        }

        setSourceData({ source_data: updatedSources });
    };


    const resetSourceData = () => {
        const updatedSources = dataObject.source_data.map(sourceData => {
            sourceData.data = [];
            return sourceData;
        });

        setSourceData({ source_data: updatedSources });

    };

    useEffect(() => {
        if (year !== null && formattedMonth !== null) {
            setSourceData1();
        }
    }, [dataObject]);

    useEffect(() => {
        // Check if this is the first change to filterValue
        if (filterChangeCountRef.current === 0) {
            // Increment the change count
            filterChangeCountRef.current++;
            return;
        }

        // If it's not the first change, run the effect
        resetSourceData();
        setSourceData1();
    }, [filterValue, distcode, procode, year, formattedMonth]);


    let col12 = 'single'
    return (
        <div className='row mt-2 '>
            <div className='col-lg-12 mt-2'>
                <div className='box-heading'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                            <span
                                className={`icon-tab ${selectedIcon === 'graph-icon' ? 'active' : ''} graph-icon`}
                                onClick={() => setSelectedIcon('graph-icon')}
                            >
                                <i className="bi bi-bar-chart-line"></i>
                            </span>
                            <span
                                className={`icon-tab ${selectedIcon === 'table-icon' ? 'active' : ''} table-icon`}
                                onClick={() => setSelectedIcon('table-icon')}
                            >
                                <i className="bi bi-table"></i>
                            </span>
                            {/* <span
                                className={`icon-tab ${selectedIcon === 'map-icon' ? 'active' : ''} map-icon`}
                                onClick={() => setSelectedIcon('map-icon')}
                            >
                                <i className="bi bi-map"></i>
                            </span> */}
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 text-center text-dark order-sm-3 order-3 order-md-2'>
                            <strong>{dataObject.indicatorName}</strong>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6 text-end order-sm-2 order-2 order-md-3'>
                            {/* <span className='icon-tab excel-icon'>
                                <i class="bi bi-file-earmark-excel"></i>
                            </span>
                            <span className='icon-tab pdf-icon'>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </span>
                            <span className='icon-tab image-icon'>
                                <i class="bi bi-image"></i>
                            </span> */}
                            <span className='icon-tab collapse-icon' onClick={toggleCollapse}>
                                <i className={isCollapsed ? 'bi bi-arrow-down-short' : 'bi bi-arrow-up-short'}></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='col-lg-12'>
                <div className='row'>

                    {dataObject?.source_data.map((sourceData, sourceIndex) => (

                        <div className={`col-lg-${sourceData.div_width}`}>
                            <div className="inner-box-chart" key={sourceIndex}>
                                {sourceData.isloading == 't' ? (
                                    <div className="loader"></div>
                                ) : (
                                    sourceData.series_type === "multi" ?
                                        <MultiSeriesChart
                                            idPrefix={dataObject.idPrefix}
                                            graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                            graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                            graphData={sourceData.data}
                                            chartType={sourceData.chart_type && sourceData.chart_type}
                                        />
                                        : <SingleSeriesChart
                                            idPrefix={dataObject.idPrefix}
                                            graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                            graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                            graphData={sourceData.data}
                                            chartType={sourceData.chart_type && sourceData.chart_type} 
                                            />
                                )}
                            </div>
                        </div>
                    ))}



                </div>
            </div> */}

            {!isCollapsed && (
                <>
                    {selectedIcon === 'graph-icon' ? (
                        <>
                            <RowChartComponent
                                sourceData={sourceData}
                                GraphWaiting={GraphWaiting}
                                dataObject={dataObject}
                                idPrefix={dataObject.idPrefix}
                                data={tabdata}
                                isPercent={isPercent}
                                col12={col12}
                            />
                        </>
                    ) : selectedIcon === 'table-icon' ? (
                        <TableComponentJquery1
                            sourceData={sourceData}
                            GraphWaiting={GraphWaiting}
                            dataObject={dataObject}
                            col12={col12}
                            idPrefix={dataObject.idPrefix}
                        />
                    ) : (
                        <div>Map</div>
                    )}
                </>
            )}
        </div>
    )
}


export default SingleRowComponent