import React from 'react';
import Circle1Component from '../components/circleProgressbar/Circle1Component';
import Circle2Component from '../components/circleProgressbar/Circle2Component';
import Circle3Component from '../components/circleProgressbar/Circle3Component';
import Circle4Component from '../components/circleProgressbar/Circle4Component';
import Circle5Component from '../components/circleProgressbar/Circle5Component';
import Circle6Component from '../components/circleProgressbar/Circle6Component';
import Circle8Component from '../components/circleProgressbar/Circle8Component';
import Circle7Component from '../components/circleProgressbar/Circle7Component';
import TableComponent1 from '../components/tableComponent/TableComponent1';
import TableComponent2 from '../components/tableComponent/TableComponent2';
import TableComponent3 from '../components/tableComponent/TableComponent3';
import TableComponentJquery1 from '../components/tableComponent/TableComponentJquery1';

const ExampleCirclePiePage = () => {
    // const circleComponents = [
    //     Circle1Component,
    //     Circle2Component,
    //     Circle3Component,
    //     Circle4Component,
    //     Circle5Component,
    //     Circle6Component,
    //     Circle7Component,
    //     Circle8Component,
    // ];

    // const CircleComponentWrapper = ({ componentName: Component, key }) => (
    //     <div key={key} className="col-lg-3">
    //         <div className="inner-circle-background">
    //             <p className="text-muted" title="Component name">
    //                 <strong>{Component.name}</strong>
    //             </p>
    //             <Component />
    //         </div>
    //     </div>
    // );

    return (
        <div>


            {/* <div className="col-lg-3">
                <div className="inner-circle-background">
                    <p className="text-muted" title="Component name">
                        <strong>Circle7Component</strong>
                    </p>
                    <Circle7Component />
                </div>
            </div> */}

            {/* <div className="container">
                <div className="row">
                    {circleComponents.map((Component, index) => (
                        <CircleComponentWrapper
                            key={index}
                            componentName={Component}
                        />
                    ))}
                </div>
            </div> */}
            {/* <h1 className="text-center">Data Tables</h1> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <TableComponent
                        1 /> */}
                        {/* <TableComponent2 /> */}
                        {/* <TableComponent3 /> */}
                        <TableComponentJquery1 />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExampleCirclePiePage;