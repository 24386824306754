import React, { useEffect, useState } from 'react'
import MapChartComponent from '../components/charts/MapChartComponent';
import { useLocation } from 'react-router-dom';
import PieChartComponent from '../components/charts/PieChartComponent';
import DataTable from 'react-data-table-component';
import PieChartsSmallComponent1 from '../components/charts/PieChartsSmall/PieChartsSmallComponent1';
import PieChartsSmallComponent2 from '../components/charts/PieChartsSmall/PeiChartsSmallComponent2';
import ColumnChartStaticComponent from '../components/charts/PieChartsSmall/ColumnChartStaticComponent';
import BarChartStaticComponent from '../components/charts/PieChartsSmall/BarChartStaticComponent';
import TabStaticComponent from '../components/charts/PieChartsSmall/TabStaticComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import TabComponent from './TabsComponent'
import MultiSeriesChart from './charts/MultiSeriesChart';
import SingleSeriesChart from './charts/SingleSeriesChart';
import SunburstChartComponent from './charts/SunburstChartComponent';
import TreeChartComponent from './charts/TreeChartComponent';
import { API__LIVE_Link } from '../commons/Constant';

const LandingComponent = ({ dataObject, graphWaiting }) => {

    const [tabdata, setTabData] = useState([]);
    const [staticData, setStaticData] = useState([]);
    const [tabledata, setTableData] = useState([]);
    const [mapdata, setMapdata] = useState([]);
    const [treemap, setTreemap] = useState([]);
    const [dataClasses, setDataClasses] = useState([]);

    useEffect(() => {
        if (tabdata.length == 0) {
            gettabdata();
            getSunburstData();
            gettabledata();

        }
        if (mapdata.length == 0) {
            getMapdata();
        }
        if (treemap.length == 0) {
            gettreedata();
        }

    }, [tabdata, mapdata, treemap])
    const columns = [
        {
            name: 'Commodity Items',
            selector: (row) => row.item,
            sortable: true,
        },
        {
            name: 'Received',
            selector: (row) => row.received,
            sortable: true,
        },
        {
            name: 'Distributed',
            selector: (row) => row.dist,
            sortable: true,
        },
        {
            name: 'Consumed',
            selector: (row) => row.consumed,
            sortable: true,
        }
    ];
    const gettreedata = () => {
        axios.get('treedata.json')
            .then(response => {
                if (response.data) {
                    setTreemap(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            });
    }
    const gettabdata = () => {
        axios.get('tabdata.json')
            .then(response => {
                if (response.data) {
                    setTabData(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            });
    }
    const gettabledata = () => {
        axios.get('tabledata.json')
            .then(response => {
                if (response.data) {
                    setTableData(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            });
    }


    const getSunburstData = () => {
        axios.get('sunburstdata.json')
            .then(response => {
                if (response.data) {
                    setStaticData(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            });
        }


    const getMapdata = () => {
        // axios.post(`${API__LIVE_Link}get_indicator_map_data.php?indid=1&fmonth=202306`)
        axios.get('mapdata.json')
            .then(response => {
                if (response.data) {
                    setMapdata(response.data);
                    // console.log('response', response.data.dataClasses)
                    setDataClasses(response.data.dataClasses)
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            });
    }

 
    return (
       
        <div className="row mt-2 this_is_Landing_component">
            <div className="col-lg-3">
                <div className="row">
                    {dataObject?.source_data.map((sourceData, sourceIndex) => (
                        sourceData.component_design_column === 1 ? (
                            <div className="col-lg-12" key={sourceIndex}>
                                <div className="inner-box-chart column3-chart">
                                    {sourceData.data.length === 0 ? (
                                        <div className="loader"></div>
                                    ) : (
                                        <>{(
                                            sourceData.chart_type === "sunburst" ? (
                                                <SunburstChartComponent
                                                    data={staticData}
                                                    height={150}
                                                />
                                            ) : (
                                                <SingleSeriesChart
                                                    idPrefix={dataObject.idPrefix}
                                                    graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                                    graphWaiting={graphWaiting}
                                                    graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                                    graphData={sourceData.data}
                                                    chartType={sourceData.chart_type && sourceData.chart_type}
                                                    series_type={sourceData.series_type}
                                                    height={150}
                                                />
                                            )
                                        )}
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : null
                    ))}
                </div>
            </div>
            <div className="col-lg-4 p-0">
                <div className="inner-chart-box map-chart-component-height">
                    {dataObject?.source_data.map((sourceData, sourceIndex) => (

                        sourceData.component_design_column === 2 ?
                            <div className="col-lg-12">
                                <div className="inner-box-chart" key={sourceIndex}>
                                    {sourceData.isloading == 'true' ? (
                                        <div className="loader"></div>
                                    ) : (
                                       

                                        <MapChartComponent
                                            idPrefix={dataObject.idPrefix}
                                            dataClasses={sourceData?.dataClasses}
                                            mapdata={sourceData?.data}
                                            title={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                            chartType={sourceData.chart_type && sourceData.chart_type}
                                            subtitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                            series_type={sourceData.series_type} />
                                    )}
                                </div>
                            </div> : <></>
                    ))}
                </div>
            </div>
            <div className="col-lg-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-chart-box height-60-chart">
                            <DataTable columns={columns} data={tabledata} fixedHeader fixedHeaderScrollHeight="300px" responsive pointerOnHover striped />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-chart-box height-60-chart">
                            {/* treemap */}
                            <TreeChartComponent treedata={treemap?.data} title={treemap?.title} subtitle={treemap?.subtitle} causeName1={treemap?.causeName} />
                            {/* <MapChartComponent mapdata={mapdata?.data} title={mapdata?.title} subtitle={mapdata?.subtitle}/> */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    {dataObject?.source_data.map((sourceData, sourceIndex) => (

                        sourceData.component_design_column === 3 ?
                            <div className="col-lg-12">
                                <div className="inner-box-chart" key={sourceIndex}>
                                    {sourceData.isloading == 'true' ? (
                                        <div className="loader"></div>
                                    ) : (
                                        <>

                                            {sourceData.chart_type === "sunburst" ? (
                                                <SunburstChartComponent data={staticData} height={150} />
                                            ) : sourceData.chart_type === "treemap" ? (
                                                <TreeChartComponent
                                                    treedata={treemap?.data}
                                                    title={treemap?.title}
                                                    subtitle={treemap?.subtitle}
                                                    causeName1={treemap?.causeName}
                                                />
                                            ) : sourceData.chart_type === "datatable" ? (
                                                // <DataTable
                                                //     columns={columns}
                                                //     data={tabledata}
                                                //     fixedHeader
                                                //     fixedHeaderScrollHeight="300px"
                                                //     responsive
                                                //     pointerOnHover
                                                //     striped
                                                // />
                                                <></>
                                            ) : (
                                                <SingleSeriesChart
                                                    idPrefix={dataObject.idPrefix}
                                                    graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                                    graphWaiting={graphWaiting}
                                                    graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                                    graphData={sourceData.data}
                                                    chartType={sourceData.chart_type && sourceData.chart_type}
                                                    series_type={sourceData.series_type}
                                                    height={150}
                                                />
                                            )}
                                        </>

                                    )}
                                </div>
                            </div> : <></>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default LandingComponent