import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faChild, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import SingleSeriesChart from './charts/SingleSeriesChart'
import PieChartsSmallComponent1 from './charts/PieChartsSmall/PieChartsSmallComponent1'
import SmallCircleChart from './charts/SmallCircleChart'
const Card5Component = ({ indicatorvalue, indicatortitle, source, subValue, cardWaiting }) => {
    const [showPieChart, setShowPieChart] = useState(false);

    useEffect(() => {
        // Check if indicatorvalue contains a percentage (e.g., "50%")
        if (indicatorvalue.includes('%')) {
            setShowPieChart(true);
        } else {
            setShowPieChart(false);
        }
    }, [indicatorvalue]);

    return (
        <div className='item card5component'>
            <div className="innerCard">
                <div className="d-flex custom-flex">

                    {showPieChart && (
                        <div>
                            
                            <SmallCircleChart percent={indicatorvalue.replace('%', '')} />
                        </div>
                    )}

                    <div className=''>
                        <h3 className='mb-0'> {cardWaiting ? <FontAwesomeIcon icon={faSpinner} spin /> : indicatorvalue} </h3>

                        <span className='align-self-end opacity-50 small ms-1'>{source}</span>
                    </div>
                </div>
                <p className="small text-center mb-0"><strong>{indicatortitle}</strong></p>

                <span className='icon-span-bg'>
                    <FontAwesomeIcon icon={faChild} />
                </span>

                <table className='table table-card-dynamic'>
                    <tbody>
                        <tr>
                            {
                                subValue?.map(({ index, title, value }) => {
                                    return (
                                        <td key={index}>{title} <br /> {value}</td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Card5Component