import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HCMap from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';

// Initialize the map module
HCMap(Highcharts);
const MapChartComponent = ({ mapdata,height,checkChildEvent, setChildState, childFunction, isloading, title, subtitle }) => {

  const handleClick = (distcode) => {
    setChildState('Updated from Child!');
    childFunction(distcode);
  };

  // const value = mapdata?.data?.length;
  //  console.log('mapdata', mapdata)

  const heightValue = Number(height);
  const extraPixels = heightValue === 2 ? 5 : heightValue === 3 ? 10 : 0;
  const calculatedHeight = height * 240 + extraPixels;

  const [count, setCount] = useState(0)
  useEffect(() => {
    // setCount(count + 1)
    if(count === 0){
        setCount(prevCount => prevCount + 1);
    }
    console.log('singleSeriesChart Callled....')
}, 
[mapdata, count])

  useEffect(() => {
    const fetchMapData = async () => {
        Highcharts.mapChart('container-map', {
          chart: {
            map: mapdata?.data,
            backgroundColor: 'transparent', 
            height: calculatedHeight + 'px',
          },
          title: {
            // text: mapdata?.title
            text: title
          },
          subtitle: {
            // text: mapdata.subtitle
            text: subtitle
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              verticalAlign: 'bottom'
            }
          },
          legend: {
            align: 'right',
            verticalAlign: 'top',
            x: -5,
            y: 20,
            floating: true,
            layout: 'vertical',
            valueDecimals: 0,
            backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255, 255, 255, 0.85)'
          },
          colorAxis: {
            dataClasses: mapdata?.dataclasses
          },
          series: [{
            data: mapdata,
            // name: mapdata.data.title,
            name: title,
            states: {
              hover: {
                color: '#BADA55'
              }
            },
            dataLabels: {
              enabled: true,
              format: '{point.name}'
            },
            point: {
              events: {
                click: function () {
                  // Assuming your 'id' is available in the point object or mapdata
                  const distcode = this.distcode || this.distcode.id || null;
                  if (checkChildEvent !== false || (checkChildEvent && checkChildEvent(distcode))) {
                    handleClick(distcode);
                  }
                },
              },
            },
          }],
        });
    };
  
    fetchMapData();
  }, [mapdata]);
  
  return <div id="container-map" style={{ width: "100%", height: calculatedHeight + 'px' }}></div>
};

export default MapChartComponent;


// import React, { useEffect, useState } from 'react';
// import Highcharts from 'highcharts';
// import HCMap from 'highcharts/modules/map';
// import HighchartsReact from 'highcharts-react-official';

// // Initialize the map module
// HCMap(Highcharts);
// const MapChartComponent = ({ mapdata, title, subtitle, dataclasses,height }) => {

//   console.log('dataClasses in Map Component', title)
//   useEffect(() => {
//     const fetchMapData = async () => {
//       Highcharts.mapChart('container-map', {
//         chart: {
//           map: mapdata,
//           backgroundColor: 'transparent', // Set the background color to transparent
//           height: height === 600 ? '100%' : `${height}px`,
//         },
//         title: {
//           text: title
//         },
//         subtitle: {
//           text: subtitle
//         },
//         mapNavigation: {
//           enabled: true,
//           buttonOptions: {
//             verticalAlign: 'bottom'
//           }
//         },
//         legend: {
//           align: 'right',
//           verticalAlign: 'top',
//           x: -5,
//           y: 20,
//           floating: true,
//           layout: 'vertical',
//           valueDecimals: 0,
//           backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255, 255, 255, 0.85)'
//         },
//         colorAxis: {
//           dataClasses: dataclasses
//         },
//         series: [{
//           data: mapdata,
//           name: title,
//           states: {
//             hover: {
//               color: '#BADA55'
//             }
//           },
//           dataLabels: {
//             enabled: true,
//             format: '{point.name}'
//           }
//         }]
//       });
//     };

//     fetchMapData();
//   }, [mapdata, dataclasses]);

//   return <div id="container-map" style={{ width: "100%", height: "calc(100vh - 220px)" }}></div>;
// };

// export default MapChartComponent;
