import React from 'react'

const OurPartnersComponent = () => {
    return (
        <section id="partners">
            <div className="container">

                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1 className="section-heading mb-3">
                            Our Partners
                        </h1>
                        <p className="small text-muted">
                            Nunc id orci risus. Cras ac leo at risus semper vulputate non quis neque. Phasellus cursus
                            interdum rutrum.
                        </p>
                    </div>
                    <div className="col-lg-12">
                        <ul className="logo-ministry">
                            <li>
                                <img src={require(`../assets/ministry_logo/ak-logo.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/b-logo.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/pk-logo.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/pn-logo.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/gb-logo.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/s-logo.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/kp-logo.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/mohnsrc.png`)} alt="" />
                            </li>
                            <li>
                                <img src={require(`../assets/ministry_logo/nih.png`)} alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPartnersComponent