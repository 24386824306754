import React from 'react'

const DataSourcesComponent = () => {
    return (
        <section id="dataSources">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1 className="section-heading mb-3">
                            Data Sources
                        </h1>
                        <p className="text-muted m-auto">
                            Data sources refer to the origins of information, ranging from databases and APIs to sensor
                            inputs, providing the raw material for analysis and decision-making in various fields. They
                            encompass structured databases, unstructured documents, and real-time streams of information
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="items">
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/dhis-logo.jpeg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/edms.jpg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/epi.jpg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/mnch-mis.jpg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/M&S.jpg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/lhw.jpg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/zm.jpg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/me.jpg`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                        </ul>
                        <ul className="items mt-3">
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/cLMIS-logo.png`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/vLMIS-logo.png`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/dhis2-logo.png`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/IDMIS-logo.png`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="circle-img">
                                    <img src={require(`../assets/IDSR-logo.png`)} className="img-fluid" alt="" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DataSourcesComponent