import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-buttons/js/dataTables.buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

const TableComponentJquery1 = ({ sourceData, col12, idPrefix, pre_index, mainindex, is_tabbed, title }) => {
    const [fullscreenTableIndex, setFullscreenTableIndex] = useState(null);
    const containerRefs = useRef([]);
    const [count, setCount] = useState(0);

    console.log('sourceData in TableComponentJquery1 : ', sourceData, title)
    const requestFullScreen = (index) => {
        const container = containerRefs.current[index];
        if (container) {
            if (container.requestFullscreen) {
                container.requestFullscreen();
            } else if (container.mozRequestFullScreen) {
                container.mozRequestFullScreen();
            } else if (container.webkitRequestFullscreen) {
                container.webkitRequestFullscreen();
            } else if (container.msRequestFullscreen) {
                container.msRequestFullscreen();
            }
            setFullscreenTableIndex(index);
        }
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setFullscreenTableIndex(null);
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (!document.fullscreenElement) {
                setFullscreenTableIndex(null);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    console.log(sourceData, 'sourceData')


    let headers = []

    if (sourceData?.data?.length > 0) {
        headers = ['Year', ...sourceData.data.map(item => item.name)];

    }

    // console.log('headers', headers)
    const generateColumns = () => {
        return [
            { name: 'Indicator' },
            { name: 'Value' }
        ];
    };
    useEffect(() => {
        setCount(count + 1)
        if (sourceData?.source_data) {
            $.fn.dataTable.ext.errMode = 'none';
            sourceData.source_data.forEach((source, sourceIndex) => {
                const table = $(`#example${idPrefix}${sourceIndex}`).DataTable({
                    dom: 'Bfrtip',
                    buttons: [
                        'copy',
                        'excel',
                        'csv',
                        'pdf',
                        'print',
                    ],
                });
                return () => {
                    table.destroy(true);
                };
            });
        }
        else if (is_tabbed === true) {

            $.fn.dataTable.ext.errMode = 'none';
            const length = sourceData?.categories?.length

            const table = $(`#example1-${idPrefix}-${pre_index}-${mainindex}-${count}`).DataTable({
                dom: 'Bfrtip',
                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf',
                    'print',
                ],
            });
            return () => {
                table.destroy(true);
            };
        }
        else if (sourceData?.categories) {
            $.fn.dataTable.ext.errMode = 'none';
            sourceData.categories.forEach((source, sourceIndex) => {
                const table = $(`#example${idPrefix}${sourceIndex}`).DataTable({
                    dom: 'Bfrtip',
                    buttons: [
                        'copy',
                        'excel',
                        'csv',
                        'pdf',
                        'print',
                    ],
                });
                return () => {
                    table.destroy(true);
                };
            });
        }


        else if (sourceData?.source) {
            $.fn.dataTable.ext.errMode = 'none';
            const table = $(`#example${idPrefix}${pre_index}-${mainindex}`).DataTable({
                dom: 'Bfrtip',
                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf',
                    'print',
                ],
            });
            return () => {
                table.destroy(true);
            };
        }

    }, [sourceData, idPrefix, pre_index]);


    if (sourceData?.source_data) {
        return (
            <div className={col12 === 'single' ? 'col-lg-12' : 'col-lg-9'}>
                <div className='row center-column-padding-0'>
                    {sourceData.source_data.map((source, sourceIndex) => (
                        <div className={`col-lg-${source.div_width}`} key={`${idPrefix}-${sourceIndex}`}>
                            <div className="inner-box-table">
                                {source?.data?.length !== undefined ? (
                                    <>
                                        <p className='text-center mb-0 pt-2 bg-heading-p table_head_title'>dataTable structure issue</p>
                                        <p className='text-center mb-0 bg-heading-p table_sub_title'>No Data available</p>
                                        <div className="loader static"></div>
                                    </>
                                ) : (
                                    <>
                                        <p className='text-center mb-0 pt-2 bg-heading-p table_head_title' title={`${source.title} ${source.source}`}>{source.title} {source.source} </p>
                                        <p className='text-center mb-0 bg-heading-p table_sub_title' title={source.subtitle}>{source.subtitle}</p>
                                        {!fullscreenTableIndex && <button className='btn-full-screen' title='full Screen' onClick={() => requestFullScreen(sourceIndex)}><FontAwesomeIcon icon={faExpand} /></button>}
                                        {fullscreenTableIndex === sourceIndex && <button onClick={exitFullScreen}>Exit Fullscreen</button>}
                                        <div className='' ref={(el) => containerRefs.current[sourceIndex] = el}>
                                            <table id={`example${idPrefix}${sourceIndex}`} className="display">
                                                <thead>
                                                    <tr>
                                                        {generateColumns().map((column, index) => (
                                                            <th key={index}>{column.name}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {source.data.data[0].data.map((rowData, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            <td>{rowData.name}</td>
                                                            <td>{rowData.y}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    if (sourceData?.source) {
        if (!sourceData.data?.data?.length) {
            return (
                <div className={col12 === 'single' ? 'col-lg-12' : 'col-lg-9'}>
                    <div className='inner-box-table'>
                        <p className='text-center mb-0 pt-2 bg-heading-p table_head_title'>No Data Available</p>
                    </div>
                </div>
            );
        }

        const source = sourceData.data.data[0];

        return (
            <div className={`col-lg-${sourceData.div_width}`}>
                <div className="inner-box-table">
                    <p className='text-center mb-0 pt-2 bg-heading-p table_head_title' title={`${sourceData.title} ${sourceData.source}`}>
                        {sourceData.title} {sourceData.source}
                    </p>
                    <p className='text-center mb-0 bg-heading-p table_sub_title' title={sourceData.subtitle}>{sourceData.subtitle}</p>
                    {!fullscreenTableIndex && (
                        <button className='btn-full-screen' title='Full Screen' onClick={() => requestFullScreen(0)}>
                            <FontAwesomeIcon icon={faExpand} />
                        </button>
                    )}
                    {fullscreenTableIndex === 0 && <button onClick={exitFullScreen}>Exit Fullscreen</button>}
                    <div className='' ref={(el) => containerRefs.current[0] = el}>
                        <table id={`example${idPrefix}${pre_index}-${mainindex}`} className="display">
                            <thead>
                                <tr>
                                    {generateColumns().map((column, index) => (
                                        <th key={index}>{column.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {source.data.map((rowData, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>{rowData.name}</td>
                                        <td>{rowData.y}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    if (is_tabbed === true) {
        const length = sourceData?.categories?.length
        if (!sourceData.data?.length) {
            return (
                <div className={col12 === 'single' ? 'col-lg-12' : 'col-lg-9'}>
                    <div className='inner-box-table'>
                        <p className='text-center mb-0 pt-2 bg-heading-p table_head_title'>No Data Available</p>
                    </div>
                </div>
            );
        }

        const source = sourceData.data[0];
        return (
            <div className={`col-lg-12`}>
                <div className="inner-box-table">
                    <p className='text-center mb-0 pt-2 bg-heading-p table_head_title' title={`${sourceData.title} ${sourceData.source}`}>
                        {sourceData.title} {sourceData.source}
                    </p>
                    <p className='text-center mb-0 bg-heading-p table_sub_title' title={sourceData.subtitle}>{sourceData.subtitle}</p>
                    {!fullscreenTableIndex && (
                        <button className='btn-full-screen' title='Full Screen' onClick={() => requestFullScreen(0)}>
                            <FontAwesomeIcon icon={faExpand} />
                        </button>
                    )}
                    {fullscreenTableIndex === 0 && <button onClick={exitFullScreen}>Exit Fullscreen</button>}
                    <div className='' ref={(el) => containerRefs.current[0] = el}>
                        {console.log('inner code', sourceData)}
                        {sourceData.data.length === 1 ? <table id={`example1-${idPrefix}-${pre_index}-${mainindex}-${count}`} className="display">
                            <thead>
                                <tr>
                                    {generateColumns().map((column, index) => (
                                        <th key={index}>{column.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {source.data.map((rowData, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>{rowData.name}</td>
                                        <td>{rowData.y}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> : <table className='table table-bordered table-striped' id={`example1-${idPrefix}-${pre_index}-${mainindex}-${count}`}>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    {sourceData.data.map((series, seriesIndex) => (
                                        <th key={seriesIndex}>{series.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sourceData.categories.map((category, catIndex) => (
                                    <tr key={catIndex}>
                                        <td>{category}</td>
                                        {sourceData.data.map((series, seriesIndex) => (
                                            <td key={seriesIndex}>
                                                {series.data.find(dataPoint => dataPoint.name === category)?.y}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                        {/* <table id={`example1-${idPrefix}-${pre_index}-${mainindex}-${count}`} className="display">
                            <thead>
                                <tr>
                                    {generateColumns().map((column, index) => (
                                        <th key={index}>{column.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {source.data.map((rowData, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>{rowData.name}</td>
                                        <td>{rowData.y}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                        {/* <table className='table table-bordered table-striped'>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    {sourceData.data.map((series, seriesIndex) => (
                                        <th key={seriesIndex}>{series.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sourceData.categories.map((category, catIndex) => (
                                    <tr key={catIndex}>
                                        <td>{category}</td>
                                        {sourceData.data.map((series, seriesIndex) => (
                                            <td key={seriesIndex}>
                                                {series.data.find(dataPoint => dataPoint.name === category)?.y}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export default TableComponentJquery1;
