import React from 'react'

import SunburstChartComponent from './charts/SunburstChartComponent'

import SingleSeriesChart from './charts/SingleSeriesChart'
import TableComponent from './TableComponent'
import TabsComponent from './TabsComponent';
import SingleSeriesChartModal from './charts/SingleSeriesChartModal';
import SingleSeriesChartNewTab from './charts/SingleSeriesChartNewTab';
import ChartComponentIndicator from './charts/ChartComponentIndicator';
import TreeChartComponent from './charts/TreeChartComponent';

const SingleRowChartComponent = ({ sourceData, GraphWaiting, dataObject, idPrefix, data, col12 }) => {
    return (
        <>
            <div className={`col-lg-${sourceData.div_width}`}>
                {console.log('sourceData', sourceData)}
                <div className="inner-box-chart" key={idPrefix}>
                    {sourceData.data?.length === 0 ? (
                        <div className="loader"></div>
                    ) : (
                        <>
                            {sourceData.chart_type === "sunburst" ? (
                                <SunburstChartComponent
                                    data={sourceData.data}
                                />
                            ) : sourceData.chart_type === "treemap" ? (
                                <TreeChartComponent
                                    data={sourceData.data}
                                />
                            ) : (
                                sourceData.is_tabbed === "false" ?
                                    <ChartComponentIndicator
                                        graphWaiting={sourceData.isloading ? sourceData.graphSubTitle : GraphWaiting}
                                        idPrefix={dataObject.idPrefix}
                                        graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                        graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                        graphData={sourceData.series_type === "multi" ? sourceData.data : sourceData.data.data}
                                        chartType={sourceData.chart_type && sourceData.chart_type}
                                        series_type={sourceData.series_type}
                                        is_percent={sourceData.data.is_percent}
                                        height={sourceData.div_height}
                                        color={sourceData.colors}
                                    />
                                    : <TabsComponent idPrefix={idPrefix} data={data} />
                            )}
                        </>

                    )}
                </div>
            </div>
        </>

    )
}

export default SingleRowChartComponent
