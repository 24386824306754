import { faChild, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Circle3Component from './circleProgressbar/Circle3Component'
const Card8Component = ({ indicatorvalue, indicatortitle, source, subValue, cardWaiting, colors, numbers }) => {
    const [showPieChart, setShowPieChart] = useState(false);

    useEffect(() => {
        if (indicatorvalue.includes('%')) {
            setShowPieChart(true);
        } else {
            setShowPieChart(false);
        }
    }, [indicatorvalue]);

    return (
        <div className='item card6component'>
            <div className="innerCard">
                <div className="d-flex custom-flex">

                    {showPieChart && (
                        <div>
                            <Circle3Component percent={indicatorvalue.replace('%', '')} colors={colors[numbers]} />
                        </div>
                    )}
                    <div className=''>
                        {!showPieChart && (
                            <>
                                <h3 className='mb-0 mt-2' style={{color: colors[numbers]}}> {cardWaiting ? <FontAwesomeIcon icon={faSpinner} spin /> : indicatorvalue} </h3>
                                <span className='align-self-end opacity-50 small ms-1'>{source}</span>
                            </>
                        )}
                    </div>
                </div>

                {showPieChart && (
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 20px" }} >
                        <span className='align-self-end opacity-50 small ms-1'>{source}</span>
                        <p className="small text-center mb-0"><strong>{indicatortitle}</strong></p>
                    </div>
                )}

                <p className="small text-center mb-0"><strong>{indicatortitle}</strong></p>
                <span className='icon-span-bg'>
                    <FontAwesomeIcon icon={faChild} />
                </span>
                <table className='table table-card-dynamic'>
                    <tbody>
                        <tr>
                            {
                                subValue?.map(({ index, title, value }) => {
                                    return (
                                        <td key={index}>{title} <br /> {value}</td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default Card8Component