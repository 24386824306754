import React from 'react'

const CategoriesSectionComponent = () => {
    return (
        <section id="categoryType">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1 className="section-heading mb-5">
                            Categories (Types of Data)
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="innercard">
                            <div className="img-bg">
                                <img src={require(`../assets/icons/menu/FpIcon.png`)} className="img-fluid" alt="" />
                            </div>
                            <p className="mb-0"><strong>Family Planning</strong></p>
                            <p><small>Family planning involves the conscious decision and actions taken by individuals or
                                couples
                                to regulate the number and spacing of their children, promoting reproductive health and
                                overall well-being. </small></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="innercard">
                            <div className="img-bg">
                                <img src={require(`../assets/icons/menu/ImmunizationIcon.png`)} className="img-fluid" alt="" />
                            </div>
                            <p className="mb-0"><strong>Immunization</strong></p>
                            <p><small>Child immunization is a crucial preventive measure involving the administration of
                                vaccines to protect against various diseases, ensuring a child's health and fostering
                                community immunity. </small></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="innercard">
                            <div className="img-bg">
                                <img src={require(`../assets/icons/menu/DiseasesIcon.png`)} className="img-fluid" alt="" />
                            </div>
                            <p className="mb-0"><strong>Infectious Diseases</strong></p>
                            <p><small>Infectious diseases result from harmful microorganisms and can spread
                                person-to-person, necessitating preventive measures like vaccinations and hygiene for
                                effective control.</small></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="innercard">
                            <div className="img-bg">
                                <img src={require(`../assets/icons/menu/NutrationIcon.png`)} className="img-fluid" alt="" />
                            </div>
                            <p className="mb-0"><strong>Nutrition</strong></p>
                            <p><small>Nutrition is the process of obtaining and utilizing food to support growth, energy,
                                and overall health, playing a pivotal role in maintaining well-being and preventing
                                nutritional deficiencies. </small></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="innercard">
                            <div className="img-bg">
                                <img src={require(`../assets/icons/menu/MchIcon.png`)} className="img-fluid" alt="" />
                            </div>
                            <p className="mb-0"><strong>Mother & Child Health</strong></p>
                            <p><small>Mother and Child Health focuses on the well-being of both mothers and children,
                                encompassing healthcare services and interventions to ensure optimal maternal and child
                                outcomes </small></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="innercard">
                            <div className="img-bg">
                                <img src={require(`../assets/icons/menu/Stock.png`)} className="img-fluid" alt="" />
                            </div>
                            <p className="mb-0"><strong>Stock</strong></p>
                            <p><small>Stock in healthcare denotes the inventory of medications and essential supplies
                                managed by institutions for continuous patient care. Effective stock management is
                                crucial for meeting treatment demands and upholding medical standards in hospitals and
                                clinics. </small></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="innercard">
                            <div className="img-bg">
                                <img src={require(`../assets/icons/menu/HR.png`)} className="img-fluid" alt="" />
                            </div>
                            <p className="mb-0"><strong>HR</strong></p>
                            <p><small>In healthcare, HR oversees personnel management and recruitment, ensuring a skilled
                                workforce and fostering a supportive environment for quality patient care. Efficient HR
                                practices are essential in maintaining a cohesive healthcare workforce.</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CategoriesSectionComponent