import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highmaps';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import accessibility from 'highcharts/modules/accessibility';
import axios from 'axios';
import { API__LIVE_Link } from '../../commons/Constant';

exporting(Highcharts);
offlineExporting(Highcharts);
accessibility(Highcharts);

const BubbleMapComponent = () => {
    const containerRef = useRef(null);
    const [geojson, setGeojson] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [geojsonResponse, dataResponse] = await Promise.all([
                    axios.get(`${API__LIVE_Link}Rohri_ucs.geojson`),
                    axios.get(`${API__LIVE_Link}exceldata.json`)
                ]);

                const geojson = geojsonResponse.data;
                const data = dataResponse.data.map(p => ({ ...p, z: p.value }));

                setGeojson(geojson);
                setData(data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!geojson || !data) return;

        const chart = Highcharts.mapChart(containerRef.current, {
            chart: {
                map: geojson
            },
            title: {
                text: 'Rohri Sindh'
            },
            tooltip: {
                pointFormat: '{point.name}<br>Lon: {point.lon}<br>Lat: {point.lat}<br>value: {point.value}'
            },
            xAxis: {
                crosshair: {
                    zIndex: 5,
                    dashStyle: 'dot',
                    snap: false,
                    color: 'gray'
                }
            },
            yAxis: {
                crosshair: {
                    zIndex: 5,
                    dashStyle: 'dot',
                    snap: false,
                    color: 'gray'
                }
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: ['printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                    }
                }
            },
            mapNavigation: {
                enabled: true,
                enableButtons: true,
                enableDoubleClickZoom: true,
                enableMouseWheelZoom: true
            },
            series: [{
                name: 'Basemap',
                mapData: geojson,
                accessibility: {
                    exposeAsGroupOnly: true
                },
                borderColor: '#606060',
                nullColor: 'rgba(200, 200, 200, 0.2)',
                showInLegend: false
            }, {
                type: 'mapbubble',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                },
                accessibility: {
                    point: {
                        valueDescriptionFormat: '{point.lhsname}, {point.lhwname}. value {point.value}. Latitude {point.lat:.2f}, longitude {point.lon:.2f}.'
                    }
                },
                name: 'Rohri - LHS',
                data: data,
                maxSize: '10%',
                color: '#34495e',
            }]
        });

        // Display custom label with lat/lon next to crosshairs
        const handleMouseMove = (e) => {
            if (!chart.lbl) {
                chart.lbl = chart.renderer.text('', 0, 0)
                    .attr({
                        zIndex: 5
                    })
                    .css({
                        color: '#505050'
                    })
                    .add();
            }

            const normalizedEvent = chart.pointer.normalize(e);

            chart.lbl.attr({
                x: normalizedEvent.chartX + 5,
                y: normalizedEvent.chartY - 22,
                text: `Lat: ${normalizedEvent.lat.toFixed(2)}<br>Lon: ${normalizedEvent.lon.toFixed(2)}`
            });
        };

        containerRef.current.addEventListener('mousemove', handleMouseMove);

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, [geojson, data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    return (
        <div id="containerBubble" ref={containerRef}></div>
    );
};

export default BubbleMapComponent;
