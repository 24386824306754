import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild } from '@fortawesome/free-solid-svg-icons';
import SlidersCards from './SlidersCards';
import axios from 'axios';
import Card2Component from './Card2Component';
import Card3Component from './Card3Component';
import Card4Component from './Card4Component';
import Card5Component from './Card5Component';
import Card6Component from './Card6Component';
import Card7Component from './Card7Component';
import Card8Component from './Card8Component';
import Card9Component from './Card9Component';
import Card10Component from './Card10Component';
import Card11Component from './Card11Component';
import Card12Component from './Card12Component';


const Slider = ({ cardData, cardslayout, cardWaiting, colors }) => {
    // console.log("cardData:", cardData)
    // const CardsComponent = cardslayout === "Card3Component" ? Card3Component : cardslayout === "SlidersCards" ? SlidersCards : Card2Component;
    const CardsComponent =
        cardslayout === "Card3Component" ? Card3Component :
            cardslayout === "SlidersCards" ? SlidersCards :
                cardslayout === "Card4Component" ? Card4Component :
                    cardslayout === "Card5Component" ? Card5Component :
                        cardslayout === "Card6Component" ? Card6Component :
                            cardslayout === "Card7Component" ? Card7Component :
                                cardslayout === "Card8Component" ? Card8Component :
                                    cardslayout === "Card9Component" ? Card9Component :
                                        cardslayout === "Card10Component" ? Card10Component :
                                            cardslayout === "Card11Component" ? Card11Component :
                                                cardslayout === "Card12Component" ? Card12Component :
                                                    Card2Component; // Default to Card2Component
    const [carouselData, setCarouselData] = useState([]);
    // Use useEffect to update carouselData when cardData changes
    useEffect(() => {
        setCarouselData(cardData);
    }, [cardData]);

    const carouselOptions = {
        items: 4,
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
                dots: false,
            },
            576: {
                items: 2,
                slideBy: 2,
                dots: false,
            },
            768: {
                items: 3,
                slideBy: 3,
                dots: false,
            },
            992: {
                items: 4,
                slideBy: 4,
                dots: false,
            },

        },
    };
    return (
        // <div>


        <OwlCarousel className='owl-theme' loop margin={10} nav {...carouselOptions}>
            {cardData?.map((item, index) => (

                // <CardsComponent
                //     key={index}
                //     indicatorvalue={item.indicatorvalue.includes('%')
                //         ? item.indicatorvalue
                //         : Number(item.indicatorvalue).toLocaleString('en-US')}
                //     indicatortitle={item.indicatortitle}
                //     source={item.source}
                //     subValue={item.subValue}
                //     cardWaiting={cardWaiting}
                //     numbers={index}
                //     colors={colors}
                // />

                <CardsComponent
                    key={index}
                    indicatorvalue={item.indicatorvalue.includes('%')
                        ? item.indicatorvalue
                        : Number(item.indicatorvalue).toLocaleString('en-US')}
                    indicatortitle={item.indicatortitle}
                    source={item.source}
                    subValue={item.subValue}
                    cardWaiting={cardWaiting}
                    numbers={index}
                    colors={colors}
                />

            ))}
        </OwlCarousel>
        // </div>
    )
}

export default Slider