import React, {useState, useEffect} from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

const Circle3Component = ({ percent, colors}) => {

    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setPercentage(percent);
    }, [percent]);

    const circularProgressbarStyles = {
        path: {
            stroke: colors,
        },
        text: {
            fill: colors,
            fontSize: '16px',
        }
    };
    return (
        <div style={{ "display": "flex", "justifyContent": "center" }}>
            <div style={{ width: "70px" }}>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                       textSize: '16px',
                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,
                     
                        // Colors
                        pathColor: `rgba(62, 52, 199, ${percentage / 100})`,
                        textColor: colors,
                        trailColor: colors,
                        backgroundColor: '#3e98c7',
                    })}
                />
            </div>
        </div>
    )
}

export default Circle3Component