import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faChild, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'

const Card2Component = ({ indicatorvalue, indicatortitle, source, subValue,cardWaiting }) => {
   
    return (
        <div className='item card2component'>
            <div className="innerCard bg-primary text-light">
                <div className="d-flex">
                    <h3 className='mb-0'> {cardWaiting ? <FontAwesomeIcon icon={faSpinner} spin /> : indicatorvalue} </h3>
                    <span className='align-self-end opacity-50 small ms-1'>{source}</span>
                </div>
                <p className="small text-light mb-0">{indicatortitle}</p>
                <span className='icon-span-bg'>
                    <FontAwesomeIcon icon={faChild} />
                </span>
                <table className='table table-card-dynamic'>
                    <tbody>
                        <tr>
                            {
                                subValue?.map(({ index, title, value }) => {
                                    return (
                                        <td key={index}>{title} <br /> {value}</td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Card2Component