import React, { useEffect, useState } from 'react'
import TopbarSelectComponent from './TopbarSelectComponent'
import getDistrictNameFromCode from './json/GetDistrictName'


const TopbarComponent = ({ isActive, toggleClass, pageTitle, selectOptions }) => {
    const defaultValue = selectOptions ? selectOptions[0] || null : null;
    function getProvinceNameFromCode(code) {
        switch (code) {
            case "":
                return 'all';
            case "1":
                return 'Punjab';
            case "2":
                return 'Sindh';
            case "3":
                return 'KPK';
            case "4":
                return 'Balochistan';
            case "5":
                return 'AJK';
            case "6":
                return 'GilgitBaltistan';
            case "7":
                return 'ICT';
            case "8":
                return 'MAKP';
            default:
                return 'Sindh';
        }
    }

    const checkYearMonth = () => {
        const storedYear = sessionStorage.getItem('year');
        const storedMonth = sessionStorage.getItem('month');
        const storedSelectedOption = sessionStorage.getItem('selectedOption');

        if (!storedYear || storedYear.length === 0) {
            // Set current year to sessionStorage
            const currentYear = new Date().getFullYear();
            sessionStorage.setItem('year', currentYear.toString());
        }

        if (!storedMonth || storedMonth.length === 0) {
            // Set current month to sessionStorage
            const currentMonth = new Date().getMonth() + 1;
            const formattedMonth = currentMonth.toString().padStart(2, '0'); // Ensure two-digit month
            sessionStorage.setItem('month', formattedMonth);

            // Update year and month to go back 4 months
            const updatedYear = parseInt(sessionStorage.getItem('year'));
            const updatedMonth = parseInt(sessionStorage.getItem('month')) - 4;

            if (updatedMonth <= 0) {
                // If month becomes negative, adjust year and month accordingly
                sessionStorage.setItem('year', (updatedYear - 1).toString());
                sessionStorage.setItem('month', (updatedMonth + 12).toString().padStart(2, '0'));
            } else {
                sessionStorage.setItem('month', updatedMonth.toString().padStart(2, '0'));
            }
        }
        if (!storedSelectedOption || storedSelectedOption.length === 0) {
            // Set selectedOption to 'fmonth' if empty
            sessionStorage.setItem('selectedOption', 'fmonth');
        }
    }

    useEffect(() => {
        const storedYear = sessionStorage.getItem('year');
        // Check if year is empty, then reload the page
        if (!storedYear || storedYear.length === 0) {
            sessionStorage.setItem('year', new Date().getFullYear().toString());
            window.location.reload();
        } else {
            // If year is not empty, proceed with the other logic
            checkYearMonth();
        }
    }, []);

    
    

    return (
        <div className='topbar' data-aos="fade-top">
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12 d-flex align-items-center'>
                    <h5 className='mb-0 title-span ms-4 text-light'>
                        {pageTitle}
                    </h5>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='select-div'>
                        {
                            selectOptions ? <TopbarSelectComponent myOptions={selectOptions} defaultValue={defaultValue} /> : <></>
                        }
                    </div>
                </div>

                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <div className='d-flex justify-content-between justify-content-md-end align-items-center h-100'>
                        <p className='text-light mb-0 mx-4'>
                            {getProvinceNameFromCode(sessionStorage.getItem('province'))} &nbsp;
                            {getDistrictNameFromCode(sessionStorage.getItem('distcode'))} &nbsp;
                            {sessionStorage.getItem('selectedOption') === 'fmonth' && (
                                <>
                                    {sessionStorage.getItem('year')}  - {sessionStorage.getItem('month')}
                                </>
                            )}
                            {sessionStorage.getItem('selectedOption') === 'fquarter' && (
                                <>
                                    {sessionStorage.getItem('year')} - Quarter
                                    {sessionStorage.getItem('quarter')}
                                </>
                            )}
                            {sessionStorage.getItem('selectedOption') === 'fyear' && (
                                <>{sessionStorage.getItem('year')}</>
                            )}
                        </p>
                        {/* <span className='me-3 text-light'>Haider</span> */}
                        <span className='filter-icon' onClick={toggleClass}>
                            <i className="bi bi-sliders"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopbarComponent