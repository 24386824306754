import React,{useState, useEffect} from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

const Circle2Component = ({ percent, colors}) => {

    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setPercentage(percent);
    }, [percent]);

    const circularProgressbarStyles = {
        path: {
            stroke: colors,
        },
        text: {
            fill: colors,
            fontSize: '16px',
        }
    };


    return (
        <div style={{ "display": "flex", "justifyContent": "center" }}>
            <div style={{ width: "70px" }}>
                <CircularProgressbar maxValue={10}
                    strokeWidth={12}
                    value={6}
                    counterClockwise={false}
                    background={true}
                    backgroundPadding={4}
                    text={`${percentage}%`} 
                    styles={circularProgressbarStyles}
                    />
            </div>
        </div>
    )
}

export default Circle2Component