


import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';

// Note 'data' is coming from dashboardPages
function TreeChartComponent({ treedata, data, height, color }) {

    const [count, setCount] = useState(0)
    useEffect(() => {
        // setCount(count + 1)
        if (count === 0) {
            setCount(prevCount => prevCount + 1);
        }
        console.log('singleSeriesChart Callled....')
    },
        [treedata, count])

    const heightValue = Number(height);
    const extraPixels = heightValue === 2 ? 5 : heightValue === 3 ? 10 : 0;
    const calculatedHeight = height * 240 + extraPixels;

    let chartData = data?.length > 0 ? data : treedata;

    const colors = Highcharts.getOptions().colors.map((c, i) =>
        Highcharts.color(Highcharts.getOptions().colors[color !== null && Number(color)])
            .brighten((i - 3) / 7)
            .get()
    );

    const defaultColor = [
        '#e63b61', // Reddish
        '#f1c40f', // Yellow
        '#9b59b6', // Purple
        '#e74c3c', // Dark Red
        '#1abc9c', // Turquoise
        '#34495e', // Dark Blue
        '#27ae60', // Emerald
        '#f39c12', // Orange
        '#2980b9', // Bright Blue
        '#d35400', // Pumpkin
        '#8e44ad', // Wisteria
        '#c0392b', // Darkish Red
        '#16a085', // Green Sea
        '#f7dc6f', // Pastel Yellow
        '#3498db', // Sky Blue
        '#e74c3c', // Tomato
        '#27ae60', // Nephritis
        '#9b59b6', // Amethyst
        '#f39c12', // Sunflower
        '#34495e', // Wet Asphalt
        '#3498db', // Blue
        '#2ecc71', // Green
        '#e74c3c', // Alizarin
        '#2ecc71', // Caribbean Green
    ];

    let colorIndex = 0;

    // Add a color key to objects without a parent key

 

    chartData.forEach(obj => {
        if (!obj.hasOwnProperty('parent')) {
            if (color !== null) {
                obj.color = colors[colorIndex % colors.length];
            }
            else{
                obj.color = defaultColor[colorIndex % colors.length];
            }
           
            colorIndex++;
        }
    });

    //   console.log('checking chartdata', chartData)
    useEffect(() => {
        if (chartData?.length > 0) {
            Highcharts.chart('container', {
                // height: "50%",           
                series: [{
                    name: 'Regions',
                    type: 'treemap',
                    layoutAlgorithm: 'squarified',
                    allowDrillToNode: true,
                    animationLimit: 1000,
                    dataLabels: {
                        enabled: false,
                    },
                    levels: [{
                        level: 1,

                        dataLabels: {
                            enabled: true
                        },
                        borderWidth: 3,
                        levelIsConstant: false

                    }, {
                        level: 1,
                        dataLabels: {
                            style: {
                                fontSize: '14px'
                            }
                        }
                    }],
                    accessibility: {
                        exposeAsGroupOnly: true
                    },
                    data: chartData
                    // data: chartData.map(item => ({
                    //     id: item.id,
                    //     name: item.name,
                    //     value: item.value,
                    //     color: item.color,
                    // }))
                }],
                subtitle: {
                    text: chartData?.subtitle,
                    align: 'center'
                },
                title: {
                    text: chartData?.title,
                    align: 'center'
                }
            });
        }

    }, [treedata, data, treedata?.title, data?.title, treedata?.subtitle, data?.subtitle, treedata && JSON.stringify(treedata)]);

    return <div id="container" style={{ height: calculatedHeight + 'px' }} />;
}

export default TreeChartComponent;