import { useState, useEffect } from 'react';

const useDefaultMonthYear = () => {
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        if (currentMonth <= 4) {
            // If the current month is January to April, set to the previous year
            setYear(currentYear - 1);
            setMonth(currentMonth + 8); // Set to the corresponding month 4 months ago
        } else {
            setYear(currentYear);
            setMonth(currentMonth - 4); // Set to the corresponding month 4 months ago
        }

        // Adjust to handle negative month values
        if (month <= 0 && month !== null) {
            setMonth(month + 12); // Wrap around to December of the previous year
        }
    }, [year, month]);

    // Ensure that the month is represented with two digits
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return { year, formattedMonth };
};

export default useDefaultMonthYear