import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ChartComponentIndicator from './charts/ChartComponentIndicator';
const PillsComponent = ({ idPrefix, data, columnNumber }) => {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    // console.log('tab component', data)
    // useEffect(()=>{
    //         setLayout(data.layout_style)

    // },[layout])
    // console.log("hhhhh",Object.keys(data).layout_style)

    return (
        // <div className={data.layout_style==="vertical"?"box-white h-100 d-flex":"box-white h-100"}>
        //     <ul className={data.layout_style==="vertical"?" nav nav-pills pb-3 table-verticle-tab":"nav nav-pills justify-content-around border-bottom pb-3"}>
        //     {Object.keys(data?.chart || {}).map((sectionKey, index) => (
        //             <li className="nav-item" key={index}>
        //                 <button
        //                     className={`nav-link ${activeTab === `tab${index + 1}` ? 'active' : ''}`}
        //                     onClick={() => handleTabChange(`tab${index + 1}`)}
        //                 >
        //                     {sectionKey}
        //                 </button>
        //             </li>
        //         ))}
        //     </ul>
        //     <div className="tab-content mt-2" style={{ background: "lightgray", padding: "15px" }}>
        //     {Object.keys(data?.chart || {}).map((sectionKey, index) => (
        //             <div className={`tab-pane ${activeTab === `tab${index + 1}` ? 'active' : ''}`} key={index} id={`tab${index + 1}Content`}>
        //                 <SurveyChartComponent
        //                     text={'value'}
        //                     data={data.chart[sectionKey]}
        //                     idPrefix={`${idPrefix}-${sectionKey}`} 
        //                     graphTitle={sectionKey} 
        //                     chart_type={data.chart[sectionKey].chart_type}
        //                 />
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div className={data?.length > 1 ? "box-white h-100" : "box-white h-100"}>
            <ul className={data?.length > 1 ? "nav nav-pills pb-3 table-vertical-tab" : "nav nav-pills justify-content-around border-bottom pb-3"}>
                {data?.map((section, index) => (
                    <li className="nav-item" key={index}>
                        <button
                            className={`nav-link ${activeTab === `tab${index}` ? 'active' : ''}`}
                            onClick={() => handleTabChange(`tab${index}`)}
                        >
                            {section.title}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="tab-content mt-2" style={{ background: "lightgray", padding: "15px" }}>
                {data?.map((section, index) => (
                    <div className={`tab-pane ${activeTab === `tab${index}` ? 'active' : ''}`} key={index} id={`${columnNumber}${idPrefix}-tab${index}Content`}>
                      
                        {activeTab === `tab${index}` && section &&
                            <ChartComponentIndicator
                                text={'value'}
                                graphData={section.data}
                                idPrefix={`${columnNumber}${idPrefix}-section-${index}`}
                                graphTitle={section.title}
                                chartType={section.chart_type}
                                is_percent={section.is_percent}
                                series_type={section.series_type}
                                height={section.div_height}
                                color={section.colors}
                              
                            />
                            // <ChartComponentIndicator
                            //             graphWaiting={sourceData.isloading ? sourceData.graphSubTitle : GraphWaiting}
                            //             idPrefix={dataObject.idPrefix}
                            //             graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                            //             graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                            //             graphData={sourceData.series_type === "multi" ? sourceData.data : sourceData.data.data}
                            //             chartType={sourceData.chart_type && sourceData.chart_type}
                            //             series_type={sourceData.series_type}
                            //             is_percent={sourceData.data.is_percent}
                            //         />
                        }
                    </div>
                ))}
            </div>
        </div>

    );
};

export default PillsComponent;
