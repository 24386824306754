import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import SingleActionComponent from './SingleActionComponent';
import SingleActionComponent2 from './SingleActionComponent2';
import SingleActionComponent3 from './SingleActionComponent3';


const AccordionComponent = ({ sections }) => {

    const [openSectionIndex, setOpenSectionIndex] = useState(0);
    const toggleSection = (index) => {
        setOpenSectionIndex((prevIndex) => (prevIndex === index ? null : index));
    };



    const getColSize = (column) => {
        switch (column) {
            case 1:
                return 12;
            case 2:
                return 6;
            case 3:
                return 4;
            case 4:
                return 3;
            default:
                return 6;
        }
    };

    const color_bg = ['#d32f2e', '#1976d3', '#5d4038', '#ef6c00', '#398e3d', '#00796a', '#8a2be1', '#81007f']

    return (
        <div>
            {sections?.map((section, index) => (
                <div key={index} className="accordion-section">
                    <div
                        className={`accordion-header ${openSectionIndex === index ? 'open' : ''}`}
                        onClick={() => toggleSection(index)}
                    >
                        {section.title}
                        <span className={`icon-arrow ${openSectionIndex === index ? 'open' : ''}`}>
                            <FontAwesomeIcon icon={faArrowDown} />
                        </span>
                    </div>
                    {openSectionIndex === index && (
                        <div className="accordion-content row">
                            {section?.data?.map((item, itemIndex) => (
                                <div className={`col-lg-${getColSize(item.column)}`} key={itemIndex}>
                                    {section.component_design === 'SingleActionComponent' ? (
                                        <SingleActionComponent props={item} />
                                    ) : section.component_design === 'SingleActionComponent3' ? (
                                        <SingleActionComponent3 props={item}  color={color_bg[itemIndex % color_bg.length]}/>
                                    ) : (
                                        <SingleActionComponent2 props={item} />
                                    )}
                                </div>
                            ))}

                        </div>
                    )}
                </div>
            ))}
        </div >
    )
}

export default AccordionComponent