import { useEffect, useState } from "react";
import { fetchDataFromApi } from "./apicall";

const useApiCall = (endpoint) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await fetchDataFromApi(endpoint);
                setData(responseData);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [endpoint]);

    return { data, error, loading };
};

export default useApiCall;