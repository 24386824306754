import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import accessibility from 'highcharts/modules/accessibility';

import axios from 'axios';
import { API__LIVE_Link } from '../../commons/Constant';

exporting(Highcharts);
offlineExporting(Highcharts);
accessibility(Highcharts);

const ClusterMapComponent = () => {
    const [geojson, setGeojson] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchTopology = async () => {
            try {
                const response = await axios.get(`${API__LIVE_Link}rohri_uc_topo.json`);
                setGeojson(response.data);
            } catch (error) {
                console.error('Error fetching topology data:', error);
            }
        };

        const fetchData = async () => {
            try {
                const response = await axios.get(`${API__LIVE_Link}exceldata.json`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTopology();
        fetchData();
    }, []);

    if (!geojson || !data) {
        return <div>Loading...</div>;
    }

    const options = {
        chart: {
            height: '230px',
            map: geojson,
            events: {
                render: function () {
                    // color clusters based on data avg
                    const mapChart = this;
                    if (mapChart.series[1] && mapChart.series[1].markerClusterInfo && mapChart.series[1].markerClusterInfo.clusters.length > 0) {
                        for (let i = 0; i < mapChart.series[1].markerClusterInfo.clusters.length; i++) {
                            if (mapChart.series[1].markerClusterInfo.clusters[i].data) {
                                let clusterData = mapChart.series[1].markerClusterInfo.clusters[i].data.map(el => el.options.value);
                                if (clusterData && clusterData.length > 0) {
                                    let sum = clusterData.reduce((a, b) => a + b, 0);
                                    let clusterPoint = mapChart.series[1].markerClusterInfo.clusters[i].point;
                                    if (clusterPoint && clusterPoint.dataLabel) {
                                        clusterPoint.dataLabel.attr({
                                            text: sum.toString()
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        title: {
            text: 'Rohri Cluster Data LHWs'
        },
        subtitle: {
            text: 'Data source'
        },
        mapNavigation: {
            enabled: true,
            enableButtons: true,
            enableDoubleClickZoom: true,
            enableMouseWheelZoom: true
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    menuItems: ['printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        tooltip: {
            formatter: function () {
                if (this.point.clusteredData) {
                    var sum = this.point.clusteredData.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.options.value;
                    }, 0);
                    return 'Total Count ' + sum;
                }
                return '<b>' + this.key + '</b><br>Value: ' + this.point.value;
            }
        },
        series: [{
            name: 'Basemap',
            borderColor: '#A0A0A0',
            nullColor: 'rgba(200, 200, 200, 0.3)',
            showInLegend: false,
        }, {
            type: 'mappoint',
            name: 'points',
            enableMouseTracking: true,
            colorKey: 'clusterValue',
            color: '#2ecc71',
            data: data,
            dataLabels: {
                enabled: true,
                inside: true,
                style: {
                    fontSize: '0.9em',
                },
                formatter: function () {
                    return this.point.value;
                }
            }
        }]
    };

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'mapChart'}
                options={options}
            />
        </div>
    );
};

export default ClusterMapComponent;
