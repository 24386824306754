import React, { Suspense } from 'react';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import drilldown from 'highcharts/modules/drilldown';
import DrilldownPage from '../../pages/DrilldownPage';
import ReactDOM from 'react-dom';

HighchartsExporting(Highcharts);
drilldown(Highcharts);

const SingleSeriesChartNewTab = ({ chartType, graphTitle, graphSubTitle, height, series_type, graphWaiting,is_percent }) => {
    const graphData = [
        {
            name: "PPFP",
            title: "Postpartum Family Planning Clients",
            y: 39637,
            drilldown: "PPFP-drilldown",
        },
        {
            name: "PAFP",
            title: "Post Abortion Family Planning Clients",
            y: 2857,
            drilldown: "PAFP-drilldown",
        },
        {
            name: "SAFP",
            title: "Short Acting Family Planning Clients",
            y: 59765,
            drilldown: "SAFP-drilldown",
        },
        {
            name: "LARCS",
            title: "Long-acting Reversible Conception Clients",
            y: 11756,
            drilldown: "LARCS-drilldown",
        }
        // ... add more data points ...
    ];

    const drilldownData = {
        "PPFP-drilldown": [
            ["Option 1", 123],
            ["Option 2", 456],
            // ... add more drill-down data ...
        ],
        "PAFP-drilldown": [
            ["Option A", 78],
            ["Option B", 90],
            // ... add more drill-down data ...
        ],
        "SAFP-drilldown": [
            ["Option X", 234],
            ["Option Y", 567],
            // ... add more drill-down data ...
        ],
        "LARCS-drilldown": [
            ["Option Z", 789],
            ["Option W", 101],
            // ... add more drill-down data ...
        ]
        // ... add more drill-down data ...
    };

    let totalValue = 100;
  //  console.log(is_percent)

  

    if (is_percent === '1') {
       
        totalValue = Math.max(...graphData?.map(item => item.y));
        //console.log(is_percent,":",graphTitle,":",totalValue)
        // totalValue = graphData.reduce((sum, item) => sum + item.y, 0);
    } 
    const handleDrilldownInNewPage = (data) => {
        const uniqueKey = `data_${Date.now()}`;
        localStorage.setItem(uniqueKey, JSON.stringify(data));
        const drilldownPageURL = `/#/drilldown/${uniqueKey}`; // Include the key in the URL
        window.open(drilldownPageURL);
      };
    
      
      
      
    const options = {
        chart: {
            type: chartType,
           // width:"100%",
            height: height === 100 ? "500" : height === 150 ? "150" : "220",
        },
        title: {
            text: graphTitle,
        },
        subtitle: {
            text: graphSubTitle,
        },
        xAxis: {
            type: 'category',
            labels: {
                style: {
                    fontSize: '9px',
                    fontWeight: 'normal', // Adjust font weight here
                },
            },
        },
        yAxis: {
            title: {
                text: false,
            },
            max: is_percent === "1" ? totalValue > 100 ? totalValue:100: undefined,
            labels: {
                style: {
                    fontSize: '9px',
                    fontWeight: 'normal', // Adjust font weight here
                    textDecoration: 'none', // Remove text decoration
                    color: '#000',
                },
            },
             
                plotLines: [{
                    color: 'red',
                    width: 1.5,
                    value: is_percent === "1" ? 100 : undefined,
                    zIndex: 5
                }],
            
        },
        
        exporting: {
            enabled: true // Enable exporting button
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b><br>Value: ${this.point.y}`;
                }
            },
        series: series_type == "multi" ? graphData.data : [
            {
                showInLegend: false,
                colorByPoint: true,
                data: graphData?.map(item => ({
                    name: item.name,
                    y: series_type === 'single' ? is_percent === "1" ? item.y : item.y : item.y,
                    
                    drilldown: item.drilldown
                })),

            }
        ],
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            },
            pie: {
                allowPointSelect: true,
                    cursor: 'pointer',
                        dataLabels: {
                    enabled: true, // Set this to true to show data labels
                        format: '<b>{point.name}</b>: {point.percentage:.2f}%'
                },
            },
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            if (this.drilldown) {
                                handleDrilldownInNewPage(drilldownData[this.drilldown]);
                            }
                        }
                    }
                }
            }
        },
      
    };

    return (
        <div>
            <Suspense fallback={<div>Loading data...</div>}>
                {graphData ? (
                    <div className="text-center mb-0">
                        <strong>
                            {graphWaiting ? (
                                <div className="loader"></div>
                            ) : (
                                <div>
                                    <HighchartsReact highcharts={Highcharts} options={options} />
                                </div>
                            )}
                        </strong>
                    </div>
                ) : (
                    <div className="loader"></div>
                )}
            </Suspense>
        </div>
    );
};

export default SingleSeriesChartNewTab;
