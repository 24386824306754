import React, { useState, useEffect } from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import ChangingProgressProvider from "./ChangingProgressProvider";

// Radial separators
import RadialSeparators from "./RadialSeparators";
const Circle8Component = ({ percent, colors }) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setPercentage(percent);
    }, [percent]);

    const circularProgressbarStyles = buildStyles({
       
        strokeLinecap: "butt"
    });

    return (
        <div style={{ "display": "flex", "justifyContent": "center" }}>
            <div style={{ width: "70px" }}>
                <CircularProgressbar
                    value={percentage}
                    color={colors}
                    text={percentage}
                    strokeWidth={50}
                    styles={circularProgressbarStyles}
                />
                 <style>
                    {`
                        .CircularProgressbar .CircularProgressbar-path {
                            stroke: #6060f3 ;
                        }
                        .CircularProgressbar-text{
                            fill: #514f4f !important;
                            font-weight: bold;
                        }
                    `}
                </style>
            </div>
        </div>
    )
}

export default Circle8Component