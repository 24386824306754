import React, { useEffect, useState } from 'react';
import MapChartComponent from '../components/charts/MapChartComponent';
import { useLocation } from 'react-router-dom';
import PieChartComponent from '../components/charts/PieChartComponent';
import DataTable from 'react-data-table-component';
import PieChartsSmallComponent1 from '../components/charts/PieChartsSmall/PieChartsSmallComponent1';
import PieChartsSmallComponent2 from '../components/charts/PieChartsSmall/PeiChartsSmallComponent2';
import ColumnChartStaticComponent from '../components/charts/PieChartsSmall/ColumnChartStaticComponent';
import BarChartStaticComponent from '../components/charts/PieChartsSmall/BarChartStaticComponent';
import TabStaticComponent from '../components/charts/PieChartsSmall/TabStaticComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild } from '@fortawesome/free-solid-svg-icons';
import Slider from '../components/Slider';
import axios from 'axios';
import LandingComponent from '../components/LandingComponent';
import { API__LIVE_Link } from '../commons/Constant';
import colors from '../components/colors';
import LandingNewComponent from '../components/LandingNewComponent';


const LandingPage = ({ onUrlChange, dashboard_id }) => {

    //
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedQuarter, setSelectedQuarter] = useState('');
    const [selectedOption, setSelectedOption] = useState('option3');
    const [data, setData] = useState([]);
    const [sourcedata, setSourceData] = useState([]);
    const [tabdata, setTabData] = useState([])
    const [increment, setIncrement] = useState(0)
    const [fetchincrement, setFetchIncrement] = useState(0)
    const [cardslayout, setCardslayout] = useState('')
    const [dashboardid, setDashboardid] = useState('')
    const [headercards, setHeaderCards] = useState([]);
    const [cardWaiting, setCardWaiting] = useState(false);
    const [graphWaiting, setGraphWaiting] = useState(false);
    const [useLandingComponent, setUseLandingComponent] = useState('LandingNewComponent')


    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    let year;
    let month;

    if (currentMonth <= 4) {
        // If the current month is January to April, set to the previous year
        year = currentYear - 1;
        month = currentMonth + 8; // Set to the corresponding month 4 months ago
    } else {
        year = currentYear;
        month = currentMonth - 4; // Set to the corresponding month 4 months ago
    }

    // Adjust to handle negative month values
    if (month <= 0) {
        month += 12; // Wrap around to December of the previous year
    }

    // Ensure that the month is represented with two digits
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;



    
    const [sessionStorageValue, setSessionStorageValue] = useState(sessionStorage.getItem('fmonth') || currentDate.getFullYear() + formattedMonth);
    const [apiData, setApiData] = useState(null);
    const [fpData, setFPData] = useState([]);
    const location = useLocation();
    const currentUrl = location.pathname;
    window.addEventListener('userLoggedIn', () => {
        localStorage.setItem('isLoggedIn', 'true');
    });

    
    const fetchCardData = () => {

        setCardWaiting(true)
        try {
            const value = sessionStorage.getItem('filter') || `${year}${month < 10 ? '0' : ''}${month}`;
            const option = sessionStorage.getItem('selectedOption') || 'fmonth'
            axios.get(`${API__LIVE_Link}get_dashboard_cards.php?dashboard_id=${dashboard_id ? dashboard_id : 2}&${option}=${value}`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setHeaderCards(response.data);
                        setCardWaiting(false);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setCardWaiting(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };

    const resetStates = () => {
        setTabData([]);
        setIncrement(0);
        setFetchIncrement(0);
        setData([]);
        setHeaderCards([]);
    };
    const gettabdata = () => {
        axios.get('tabdata.json')
            .then(response => {
                if (response.data) {
                    setTabData(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            });
    }
    const fetchData = async () => {
        try {
            axios.get(`${API__LIVE_Link}get_dashboard_configuration.php?dashboard_id=${dashboard_id ? dashboard_id : 1}`)
                // axios.get('data2.json')
                .then(response => {

                    // Handle successful response
                    if (response.data) {

                        setUseLandingComponent(response.data.mainLanding)
                        setData(response.data.sections);
                        setCardslayout(response.data.cards_layout);
                        setIncrement(0);
                        fetchCardData();
                        gettabdata();

                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const setSourceData1 = () => {
        const value = sessionStorage.getItem('filter') || `${year}${month < 10 ? '0' : ''}${month}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'

        const updatedState = data?.map(item => {

            const updatedSources = item.source_data?.map(sourceData => {

                // setUseLandingComponent(item.sections.component_design)

                axios.get(`${API__LIVE_Link}${sourceData.api_link}&${option}=${value}&level=district`)
                    .then(response => {
                        // Handle successful response
                        if (response.data) {
                            sourceData.api_link.includes("") ?
                                sourceData.data = response?.data : sourceData.data = response?.data.data
                        }

                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error fetching data:', error);
                        console.log("Error Display");
                    });
                return sourceData;
            });
            return { ...item, source_data: updatedSources };
        });
        setSourceData(updatedState);
    };


    // const emptySourceData = () => {
    //     const updatedState = data?.map(item => {
    //         const updatedSources = item.source_data?.map(sourceData => {
    //             sourceData.data = []; // Set 'data' to an empty array
    //             return sourceData;
    //         });
    //         return { ...item, source_data: updatedSources };
    //     });
    //     setSourceData(updatedState);
    // };

    useEffect(() => {
        const currentUrl = location.pathname;
        if (currentUrl !== sessionStorageValue) {
            onUrlChange(currentUrl);
            resetStates();
            fetchData();
            setSessionStorageValue(currentUrl);
        }
    }, [location.pathname]);

    useEffect(() => {

        fetchCardData();
        // emptySourceData();
        setSourceData1();
    }, [sessionStorage.getItem('filter'), sessionStorage.getItem('distcode')])

    useEffect(() => {
        setSourceData1();
    }, [data]);


    return (
        <>

            <Slider cardWaiting={cardWaiting} cardData={headercards} cardslayout={cardslayout} colors={colors} />

            {sourcedata.map((item, index) => {
                const LandingComponentToUse = useLandingComponent === "LandingNewComponent"
                    ? LandingNewComponent
                    : LandingComponent;

                return (
                    <LandingComponentToUse
                        key={index}
                        dataObject={item}
                        graphWaiting={graphWaiting}
                    // tabdata={tabdata}
                    />
                );
            })}

        </>
    )
}

export default LandingPage