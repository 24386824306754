const colors = [
    '#e63b61', // Reddish
    '#3498db', // Blue
    '#2ecc71', // Green
    '#f1c40f', // Yellow
    '#9b59b6', // Purple
    '#e74c3c', // Dark Red
    '#1abc9c', // Turquoise
    '#34495e', // Dark Blue
    '#27ae60', // Emerald
    '#f39c12', // Orange
    '#2980b9', // Bright Blue
    '#d35400', // Pumpkin
    '#8e44ad', // Wisteria
    '#c0392b', // Darkish Red
    '#16a085', // Green Sea
    '#f7dc6f', // Pastel Yellow
    '#3498db', // Sky Blue
    '#e74c3c', // Tomato
    '#27ae60', // Nephritis
    '#9b59b6', // Amethyst
    '#f39c12', // Sunflower
    '#34495e', // Wet Asphalt
    '#e74c3c', // Alizarin
    '#2ecc71', // Caribbean Green
  ];
  
  export default colors;