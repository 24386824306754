import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import { API__LIVE_Link } from '../../commons/Constant';
const columns = [
    {
        name: 'distcode',
        selector: row => row.distcode,
        sortable: true,
    },
    {
        name: 'name',
        selector: row => row.name,
        sortable: true,
    }, {
        name: 'Value',
        selector: row => row.y,
        sortable: true,
    },
];

function TableComponent3() {

    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();

    const fetchData = async () => {
        try {
            const response = await axios.get(`${API__LIVE_Link}get_indicator_data.php?indid=161&fmonth=202308`);
            setData(response.data.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

    }, [])

    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data?.filter(
        (item) =>
            String(item.distcode).toLowerCase().includes(searchText.toLowerCase()) ||
            String(item.name).toLowerCase().includes(searchText.toLowerCase()) ||
            String(item.y).toLowerCase().includes(searchText.toLowerCase())
        // ||
        // String(item.gender).toLowerCase().includes(searchText.toLowerCase()) ||
        // String(item.email).toLowerCase().includes(searchText.toLowerCase()) ||
        // String(item.phone).toLowerCase().includes(searchText.toLowerCase())
    );

    const handleChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        console.log('Selected Rows: ', selectedRows);
    };

    return (
        <div className='seprate-datatable'>
            <h3 className="text-center">
                Selection row table
            </h3>
            <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
                className='form-control mb-1 table-search w-25'
            />
            <DataTable columns={columns} data={filteredData} sort selectableRows
                onSelectedRowsChange={handleChange} />
        </div>
    );
}

export default TableComponent3;
