import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-buttons/js/dataTables.buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

const TableComponentJquery2 = ({sourceData,  col12, idPrefix, page }) => {
    const [fullscreenTableIndex, setFullscreenTableIndex] = useState(null);
    const containerRefs = useRef([]);

    // console.log('sourceData', )
    // const sourceData = {
    //     "source": "DHIS",
    //     "title": "FP Clients",
    //     "subtitle": "Commodity Wise",
    //     "series_type": "single",
    //     "isloading": "false",
    //     "chart_type": "pie",
    //     "api_link": "dhisfpservicesdata.json?id=",
    //     "div_width": "12",
    //     "div_height": "1",
    //     "is_tabbed": "false",
    //     "component_design_column": "1",
    //     "data": {
    //         "categories": [
    //             "PPFP",
    //             "PAFP",
    //             "SAFP",
    //             "LARCS"
    //         ],
    //         "data": [
    //             {
    //                 "name": "PPFP",
    //                 "title": "Postpartum Family Planning Clients",
    //                 "y": 29637
    //             },
    //             {
    //                 "name": "PAFP",
    //                 "title": "Post Abortion Family Planning Clients",
    //                 "y": 2857
    //             },
    //             {
    //                 "name": "SAFP",
    //                 "title": "Short Acting Family Planning Clients",
    //                 "y": 39765
    //             },
    //             {
    //                 "name": "LARCS",
    //                 "title": "Long-acting reversible conception Clients",
    //                 "y": 11756
    //             }
    //         ]
    //     }
    // };

    const containerRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const requestFullScreen = (index) => {
        const container = containerRefs.current[index];

        if (container) {
            if (container.requestFullscreen) {
                container.requestFullscreen();
            } else if (container.mozRequestFullScreen) {
                container.mozRequestFullScreen();
            } else if (container.webkitRequestFullscreen) {
                container.webkitRequestFullscreen();
            } else if (container.msRequestFullscreen) {
                container.msRequestFullscreen();
            }
            setFullscreenTableIndex(index);
        }
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setFullscreenTableIndex(null);
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
        if (!document.fullscreenElement) {
            setFullscreenTableIndex(null);
        }
    };

    const generateColumns = (data) => {
        if (data.series_type === 'single') {
            if (data.data && typeof data.data === 'object' && Array.isArray(data.data.data)) {
                if (data.data.data.length > 0) {
                    return Object.keys(data.data.data[0])
                        .filter(key => key !== 'indid') // Exclude 'indid'
                        .map((key) => ({
                            name: key === 'y' ? data.title : key,
                        }));
                }
                return [];
            }
        }
        if (data.series_type === 'multi') {
            if (data.data && typeof data.data === 'object' && Array.isArray(data.data.data)) {
                const names = data.data.data.map(item => ({ name: item.name }));
                return names;
            }
        }

        return [];
    };

    const tableRef = useRef(null);
    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        const table = $(`#example${idPrefix}`).DataTable({
            dom: 'Bfrtip',
            buttons: [
                'copy',
                'excel',
                'csv',
                'pdf',
                'print',
            ],
        });
        return () => {
            table.destroy(true);
        };
    }, []);

    return (
        <>
            <div className={col12 === 'single' ? 'col-lg-12' : 'col-lg-9'}>
                <div className='row center-column-padding-0'>
                    <div className={`col-lg-${sourceData.div_width * 4}`}>
                        <div className="inner-box-table">
                            {sourceData?.data?.length !== undefined ? (
                                <>
                                    <p className='text-center mb-0 pt-2 bg-heading-p table_head_title'>dataTable structure issue</p>
                                    <p className='text-center mb-0 bg-heading-p table_sub_title'>No Data available</p>
                                    <div className="loader static"></div>
                                </>
                            ) : (
                                <>
                                    <p className='text-center mb-0 pt-2 bg-heading-p table_head_title' title={sourceData.title + sourceData.source}>{sourceData.title} {sourceData.source} </p>
                                    <p className='text-center mb-0 bg-heading-p table_sub_title' title={sourceData.subtitle}>{sourceData.subtitle}</p>
                                    {!fullscreenTableIndex && <button className='btn-full-screen' title='full Screen' onClick={() => requestFullScreen(0)}><FontAwesomeIcon icon={faExpand} /></button>}
                                    {fullscreenTableIndex && <button onClick={exitFullScreen}>Exit Fullscreen</button>}
                                    <div className='' ref={(el) => containerRefs.current[0] = el}>
                                        <table ref={tableRef} id={`example${idPrefix}`} className="display">
                                            <thead>
                                                <tr>
                                                    {generateColumns(sourceData).map((column, index) => (
                                                        <th key={index}>{column.name}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sourceData.series_type === 'single' && sourceData.data.data && Array.isArray(sourceData.data.data) && sourceData.data.data.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {generateColumns(sourceData).map((column, colIndex) => (
                                                            <td key={colIndex}>
                                                                {column.name === sourceData.title ? row.y : row[column.name]}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableComponentJquery2;
