import React, { useState } from 'react'
import CategoriesSectionComponent from './CategoriesSectionComponent'
import BackgroundParallaxComponent from './BackgroundParallaxComponent'
import AnimateSectionComponent from './AnimateSectionComponent'
import FooterSectionComponent from './FooterSectionComponent'
import { useNavigate } from 'react-router-dom'
import './../assets/landing.css'
import AboutUsComponent from './AboutUsComponent'
import DataSourcesComponent from './DataSourcesComponent'
import OurPartnersComponent from './OurPartnersComponent'
import axios from 'axios'
import { API__LIVE_Link } from '../commons/Constant'
import SmallCircleChart from './charts/SmallCircleChart'
import Circle1Component from './circleProgressbar/Circle1Component'
const LandingLoginComponent1 = ({ onLogin }) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('password', password)
        try {
            const response = await axios.post(`${API__LIVE_Link}api_login.php`, {
                username: username,
                password: password,
            });

            if (response.data.result === "success") {
                onLogin();
                window.history.replaceState(null, '', '/');
                navigate('/');
            }
            else {
                alert('Username or password is not correct...!')
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            console.log("Error Display");
        }
    };

    return (
        <>
            <header>
                {/* <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Login</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form action="">
                                    <p className="text-center text-primary fw-bold">Welcome to the Integrated Health Dashboard T...
                                    </p>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label"> <i
                                            className="bi bi-person text-primary"></i> Username</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label"><i
                                            className="bi bi-key text-primary"></i> Password</label>
                                        <input type="password" className="form-control" id="exampleFormControlInput1"
                                            placeholder="" />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleSubmit} className="btn btn-primary d-block w-100 shadow mb-3 mt-2">Login</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div id="hero">
                    <div className="container">
                        <div className="left-side-hero">
                            <img src={require(`../assets/logo.png`)} className="img-fluid logo-image" alt="" />
                            <h1 className="heading">
                                Integrated Health Dashboard
                            </h1>
                            <p>
                                A digital Health Dashboard analyzes vital health metrics like Family Planning, Immunization,
                                Infectious Diseases, Nutrition, and Mother & Child Health. It acts as a central hub, aggregating
                                data for insights that empower healthcare professionals and policymakers. The goal is to refine
                                decision-making, allocate resources efficiently, and enhance health outcomes for diverse
                                populations
                                <br></br>
                                <button type="button" className="btn btn-primary mt-3 px-5" onClick={handleOpenModal}>
                                    Login
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </header>

            <AboutUsComponent />
            <CategoriesSectionComponent />
            <DataSourcesComponent />
            <OurPartnersComponent />
            <FooterSectionComponent />
           
            {/* <BackgroundParallaxComponent />
            <AnimateSectionComponent />
            <FooterSectionComponent /> */}
            <div className='container'>

                {/*  */}
                <div className="login-main-wrapper">

                    {/* <img src={require(`../assets/icons/menu/info1.png`)} alt='info graphics' className='info1' />
          <img src={require(`../assets/ministry_logo/us-logo.png`)} alt='USAID logo' className='usaid1' /> */}

                    <div className='p-2'>
                        {/* <h1 className="text-center fw-bold">
              Integrated Health Dashboard
            </h1> */}

                        {/*  */}
                        <div>


                            {/* Modal */}
                            <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Login</h5>
                                            <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='login-box'>
                                                {/* <h2 className='text-center'>Login</h2> */}
                                                <p><small className='text-primary'>Welcome to the Integrated Health Dashboard...</small></p>
                                                <form onSubmit={handleSubmit}>
                                                    <div className='form-group'>
                                                        <label htmlFor="username">Username:</label>
                                                        <input
                                                            type="text"
                                                            id="username"
                                                            name="username"
                                                            className='form-control'
                                                            value={username}
                                                            onChange={handleUsernameChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='form-group mt-2'>
                                                        <label htmlFor="password">Password:</label>
                                                        <input
                                                            type="password"
                                                            id="password"
                                                            name="password"
                                                            className='form-control'
                                                            value={password}
                                                            onChange={handlePasswordChange}
                                                            required
                                                        />
                                                    </div>
                                                    <button type="submit" className='btn btn-primary mt-4 d-block w-100'>Login</button>
                                                </form>
                                                {error && <p className="error">{error}</p>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* Modal backdrop */}
                            {showModal && <div className="modal-backdrop fade show" onClick={handleCloseModal}></div>}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingLoginComponent1