import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild, faSpinner } from '@fortawesome/free-solid-svg-icons';

const SlidersCards = ({ indicatorvalue, indicatortitle, source, cardWaiting }) => {


    return (
        <div className='item'>
            <div className="single-card bg-blue">
                <h1 className="heading">
                    {cardWaiting ?  <FontAwesomeIcon icon={faSpinner} spin />: indicatorvalue} 
                </h1>
                <span className="title">
                    {indicatortitle}
                </span>
                <span className="icon">
                    <FontAwesomeIcon icon={faChild} />
                </span>
                <span className="source">
                    {source}
                </span>
            </div>
        </div>
    )
}

export default SlidersCards;