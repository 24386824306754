import React, { useEffect, useState } from 'react'
import getDistrictNameFromCode from './json/GetDistrictName'


const TopbarLandingPage = ({ isActive, toggleClass, pagetitle, url }) => {
    function extractDataFromURL(url) {
        if (url) {
            const parts = url?.split('/');

            const dataPart = parts[parts.length - 1];
            return dataPart;
        }
        else {
            return null;
        }
    }
    function getProvinceNameFromCode(code) {
        switch (code) {
            case "":
                return 'all';
            case "1":
                return 'Punjab';
            case "2":
                return 'Sindh';
            case "3":
                return 'KPK';
            case "4":
                return 'Balochistan';
            case "5":
                return 'AJK';
            case "6":
                return 'GilgitBaltistan';
            case "7":
                return 'ICT';
            case "8":
                return 'MAKP';
            default:
                return 'Sindh';
        }
    }
    const dataPart = extractDataFromURL(url); // Returns "data_1695894329053"
    const storedData = sessionStorage.getItem(dataPart);
    const data1 = storedData ? JSON.parse(storedData) : null;
    return (
        <div className='topbar' data-aos="fade-top">
            <div className='row'>
                <div className='col-lg-9 col-md-6 col-sm-12 d-flex align-items-center justify-content-between'>
                    <h5 className='mb-0 title-span ms-4 text-light min-height-40'>
                        {pagetitle ? pagetitle : `Indicator: ${data1.title}`}
                    </h5>

                </div>

                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='d-flex justify-content-between justify-content-md-end align-items-center h-100'>
                        <p className='text-light mb-0 mx-4'>
                            {getProvinceNameFromCode(sessionStorage.getItem('province'))} &nbsp;
                            {getDistrictNameFromCode(sessionStorage.getItem('distcode'))} &nbsp;
                            {sessionStorage.getItem('selectedOption') === 'fmonth' && (
                                <>
                                    {sessionStorage.getItem('year')} - {sessionStorage.getItem('month')}
                                </>
                            )}
                            {sessionStorage.getItem('selectedOption') === 'fquarter' && (
                                <>
                                    {sessionStorage.getItem('year')} - Quarter
                                    {sessionStorage.getItem('quarter')}
                                </>
                            )}
                            {sessionStorage.getItem('selectedOption') === 'fyear' && (
                                <>{sessionStorage.getItem('year')}</>
                            )}
                        </p>
                        <span className='filter-icon' onClick={toggleClass}>
                            <i className="bi bi-sliders"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopbarLandingPage