
import React, { useEffect, useState, useRef } from 'react'
import Slider from '../components/Slider';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API__LIVE_Link } from '../commons/Constant';
import colors from '../components/colors';
import AccordionComponent from '../components/actionDashboard/AccordionComponent';
import useDefaultMonthYear from '../feature/useDefaultMonthYear';

const ActionDashboardPage = ({ onUrlChange, dashboard_id }) => {

    const { year, formattedMonth } = useDefaultMonthYear();
    let i = 0;

    const location = useLocation();
    const currentUrl = location.pathname;
    const [data, setData] = useState([]);
    const [increment, setIncrement] = useState(0)
    const [cardslayout, setCardslayout] = useState('')
    const [sourceData, setSourceData] = useState([])
    const [headercards, setHeaderCards] = useState([]);
    const [actionData, setActionData] = useState([]);
    const [cardWaiting, setCardWaiting] = useState(false);
    const [sessionStorageValue, setSessionStorageValue] = useState();
    const filterValue = sessionStorage.getItem('filter');
    const filterChangeCountRef = useRef(0);
    const distcode = sessionStorage.getItem('distcode');
    const procode = sessionStorage.getItem('province')

    useEffect(() => {
        const disableBackButton = () => {
            window.history.pushState(null, null, window.location.href);
        };
        window.addEventListener('popstate', disableBackButton);
        return () => {
            window.removeEventListener('popstate', disableBackButton);
        };
    }, []);


    const fetchActionData = () => {
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        try {
            axios.get(`${API__LIVE_Link}action_dashboard.php?dashboard_id=${dashboard_id}&distcode=${distcode}&procode=${procode}&${option}=${value}`)
                .then(response => {
                    if (response.data) {
                        console.log('fetchActionData response: ', typeof response)
                        setActionData(response)
                    }
                })
                .catch(error => {
                    console.error('Error fetching fetchActionData:', error)
                })
                .finally(() => {
                    console.log('final fetchActionData')
                });
        }
        catch (error) {
            console.log('Error fetching fetchActionData :', error)
        }
    }



    const fetchCardData = () => {
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        try {
            setCardWaiting(true)
            axios.get(`${API__LIVE_Link}get_dashboard_cards.php?dashboard_id=${dashboard_id}&distcode=${distcode}&procode=${procode}&${option}=${value}`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setHeaderCards(response.data);
                        console.log('fetchActionData setHeaderCards: ', typeof response.data)
                        setCardWaiting(false);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setCardWaiting(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    window.addEventListener('userLoggedIn', () => {
        localStorage.setItem('isLoggedIn', 'true');
    });
    const fetchData = async () => {
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        // const value = sessionStorage.getItem('filter') || '202306'
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;

        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        try {
            // axios.get(`${API__LIVE_Link}get_dashboard_configuration.php?dashboard_id=${dashboard_id}`)
            //axios.get('actiondata.json')
            axios.get(`${API__LIVE_Link}action_dashboard.php?dashboard_id=${dashboard_id}&distcode=${distcode}&procode=${procode}&${option}=${value}`)
                .then(response => {
                    if (response.data) {

                        setData(response.data.sections);
                        setCardslayout(response.data.cards_layout);
                        setIncrement(0);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const resetStates = () => {
        setIncrement(0);
        setData([]);
        setSourceData([]);
    };
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const currentUrl = location.pathname;
        if (currentUrl !== sessionStorageValue) {
            setLoading(true); // Set loading to true when starting to fetch data
           
            onUrlChange(currentUrl);
            resetStates();
           
            if (year !== null && formattedMonth !== null) {
                fetchActionData();
                fetchCardData();
                fetchData();
            }
            setSessionStorageValue(currentUrl);
        }
    }, [location.pathname, actionData]);

    useEffect(() => {
        if (filterChangeCountRef.current === 0) {
            filterChangeCountRef.current++;
            return;
        }


        if (year !== null && formattedMonth !== null) {
            fetchCardData();
            fetchActionData();
            fetchData();
        }
    }, [filterValue, distcode, procode, year, formattedMonth]);

    useEffect(() => {
        if (actionData.data > 0) {
            setLoading(false); // Set loading to false when data has been fetched
        }
    }, [data, actionData]);


    console.log('cardslayout--', cardslayout)
    return (
        <div className='dashboard-page'>
            <Slider cardWaiting={cardWaiting} cardData={headercards} cardslayout={cardslayout} colors={colors} />
            {actionData?.data && <AccordionComponent sections={data} />}
        </div>
    )
}
export default ActionDashboardPage