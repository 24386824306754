import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Card3Component = ({ indicatorvalue, indicatortitle, source, subValue, cardWaiting }) => {
    return (
        <div className='card3Component border rounded overflow-hidden'>
            <div className="inner-card p-3">
                <p className='mb-0 small text-muted text-center inner-card-title'><small>{indicatortitle}</small></p>
                <p className="text-center mb-0"><strong>{cardWaiting ? <FontAwesomeIcon icon={faSpinner} spin /> : indicatorvalue} </strong> <span className="small"><small className='text-danger'>{source}</small></span></p>
                <table className='table table-bordered'>
                    <tbody>
                        <tr>
                            {
                                subValue?.map(({ index, title, value }) => {
                                    return (
                                        <td key={index} className='text-center'><strong className='small text-primary'>{title}</strong> <br /> <small className='text-success'>{value}</small></td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Card3Component