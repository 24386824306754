import React, { useEffect, useState, useRef } from 'react'
import './../indstyle.css'
import axios from 'axios';
import { API__LIVE_Link } from '../commons/Constant';
import IndicatorDashboardComponent from '../components/IndicatorDashboardComponent';
import { useParams } from 'react-router-dom';
import useDefaultMonthYear from '../feature/useDefaultMonthYear';

const IndicatorDashboardPage = () => {
    const filterChangeCountRef = useRef(0);
    const [GraphWaiting, setGraphWaiting] = useState(true);
    const [data, setData] = useState([]);
    const [mapData, setMapData] = useState([]);
    const [indicatorValue, setIndicatorValue] = useState([]);
    const [indicatorValue2, setIndicatorValue2] = useState([]);
    const [MapWaiting, setMapWaiting] = useState(true);
    const [indicatorWaiting, setIndicatorWaiting] = useState(true);
    const [indicatorWaiting2, setIndicatorWaiting2] = useState(true);
    const { key } = useParams();
    const storedData = sessionStorage.getItem(key);
    // console.log(key)
    const data1 = storedData ? JSON.parse(storedData) : null;
    // console.log('Parsed Data:', data1);
    const filterValue = sessionStorage.getItem('filter');
    const distcode = sessionStorage.getItem('distcode');
    const procode = sessionStorage.getItem('province')

    const { year, formattedMonth } = useDefaultMonthYear();

    console.log('formattedMonth', year)

    useEffect(() => {
        window.addEventListener('userLoggedIn', () => {
            localStorage.setItem('isLoggedIn', 'true');
        });



        fetchMapData();


        // fetchData();
        // fetchMapData();
        // fetchindicatorData();
        const distcode = sessionStorage.getItem('distcode')

        if (formattedMonth !== '0null') {
            if (distcode !== undefined || 'undefined') {
                fetchindicatorData2();
            }
            fetchData();
        }
    }, [year, formattedMonth])


    const fetchData = () => {

        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;
        try {
            setGraphWaiting(true)
            axios.get(`${API__LIVE_Link}get_indicator_data.php?indid=${data1.data}${distQueryParam}${provQueryParam}&${option}=${value}&level=district`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setData(response.data);
                        //      console.log("Response data...", response.data)
                        setGraphWaiting(false);

                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setGraphWaiting(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const fetchMapData = () => {

        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        // const value = sessionStorage.getItem('filter') || '202306'
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;
        try {
            setMapWaiting(true)
            axios.get(`${API__LIVE_Link}get_indicator_map_data.php?indid=${data1.data}${distQueryParam}${provQueryParam}&${option}=${value}&level=district`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setMapData(response.data);
                        //  console.log("Response data Map...", response.data)
                        setMapWaiting(false);

                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setMapWaiting(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if (filterChangeCountRef.current === 0) {
            // Increment the change count
            filterChangeCountRef.current++;
            return;
        }


        fetchMapData();
        const distcode = sessionStorage.getItem('distcode')

        if (formattedMonth !== '0null') {
            if (distcode !=  'undefined') {
                fetchindicatorData2();
            }
            fetchData();
        }


    }, [filterValue, distcode, procode])

    const fetchindicatorData = () => {

        // const value = sessionStorage.getItem('filter') || '202306'
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;

        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;
        try {
            setIndicatorWaiting(true)
            axios.get(`${API__LIVE_Link}get_indicator_data.php?indid=${data1.data}&${distQueryParam}${provQueryParam}&${option}=${value}`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setIndicatorValue(response.data);
                        //  console.log("Response data indicator...", response.data)
                        setIndicatorWaiting(false);

                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setIndicatorWaiting(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const fetchindicatorData2 = () => {
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province')
        const distcode = sessionStorage.getItem('distcode')
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;
        try {
            setIndicatorWaiting2(true)
            axios.get(`${API__LIVE_Link}get_indicator_data_monthwise.php?indid=${data1.data}${distQueryParam}${provQueryParam}&${option}=${value}`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setIndicatorValue2(response.data);
                        // console.log("Response data indicator...", response.data)
                        setIndicatorWaiting2(false);

                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setIndicatorWaiting2(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    // console.log('procode --', procode)

    return (
        <>
            <IndicatorDashboardComponent
                data={data}
                mapdata={mapData}
                graphWaiting={GraphWaiting}
                mapwaiting={MapWaiting}
                indicatordata={indicatorValue}
                indicatordata2={indicatorValue2}
                height={600}
                distcode={distcode}
                title='title'
                subtitle='subtitle'
            // tabdata={tabdata}
            />
        </>
    )
}

export default IndicatorDashboardPage