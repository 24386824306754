import React, { Suspense } from 'react';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import drilldown from 'highcharts/modules/drilldown';

HighchartsExporting(Highcharts);
drilldown(Highcharts);

const ChartComponentIndicator = ({ chartType, graphTitle, graphSubTitle, graphData, color, height, series_type, graphWaiting, is_percent, is_tab }) => {
  
    const defaultColor = [
        '#e63b61', // Reddish
        '#3498db', // Blue
        '#2ecc71', // Green
        '#f1c40f', // Yellow
        '#9b59b6', // Purple
        '#e74c3c', // Dark Red
        '#1abc9c', // Turquoise
        '#34495e', // Dark Blue
        '#27ae60', // Emerald
        '#f39c12', // Orange
        '#2980b9', // Bright Blue
        '#d35400', // Pumpkin
        '#8e44ad', // Wisteria
        '#c0392b', // Darkish Red
        '#16a085', // Green Sea
        '#f7dc6f', // Pastel Yellow
        '#3498db', // Sky Blue
        '#e74c3c', // Tomato
        '#27ae60', // Nephritis
        '#9b59b6', // Amethyst
        '#f39c12', // Sunflower
        '#34495e', // Wet Asphalt
        '#e74c3c', // Alizarin
        '#2ecc71', // Caribbean Green
    ];

    const colors = Highcharts.getOptions().colors.map((c, i) =>
        Highcharts.color(Highcharts.getOptions().colors[color !== null && Number(color)])
            .brighten((i - 3) / 7)
            .get()
    );

    let totalValue = 100;
    if (is_percent === '1') {
        totalValue = Math.max(...graphData?.map(item => item.y));
    }

    const handleIndicatorInNewPage = (data, title) => {
        const uniqueKey = `data_${Date.now()}`;
        const storageData = { data, title };
        sessionStorage.setItem(uniqueKey, JSON.stringify(storageData));
        const inddashboardURL = `/#/inddashboard/${uniqueKey}`;
        window.open(inddashboardURL);
    };

    const heightValue = Number(height);
    const extraPixels = heightValue === 2 ? 5 : heightValue === 3 ? 10 : 0;
    const calculatedHeight = heightValue * 240 + extraPixels;

    const options = {
        chart: {
            type: chartType,
            height: calculatedHeight + 'px'
        },
        title: {
            text: graphTitle,
        },
        subtitle: {
            text: graphSubTitle,
        },
        xAxis: {
            type: 'category',
            labels: {
                style: {
                    fontSize: '9px',
                    fontWeight: 'normal', 
                },
            },
        },
        yAxis: {
            title: {
                text: false,
            },
            max: is_percent === "1" ? totalValue > 100 ? totalValue : 100 : undefined,
            labels: {
                style: {
                    fontSize: '9px',
                    fontWeight: 'normal', 
                    textDecoration: 'none', 
                    color: '#000',
                },
            },
            plotLines: [{
                color: 'red',
                width: 1.5,
                value: is_percent === "1" ? 100 : undefined,
                zIndex: 5
            }],
        },

        exporting: {
            enabled: true // Enable exporting button
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b><br>Value: ${this.point.y}`;
            }
        },
        // series: series_type === "multi" 
        // ? graphData.data 
        // : [
        //     {
        //         showInLegend: false,
        //         colorByPoint: true,
        //         data: [graphData?.length > 0 && graphData?.map(item => ({
        //             name: item.name,
        //             // y: series_type === 'single' ? is_percent === "1" ? item.y : item.y : item.y,
        //             y: item.y,
        //             drilldown: item.drilldown,
        //             indid: item.indid,
        //         }))],

        //         point: {
        //             events: {
        //                 click: function () {
        //                     if(this.indid)
        //                     handleIndicatorInNewPage(this.indid,graphTitle);
        //                 }
        //             }
        //         }

        //     }
        // ],

        series: graphData?.length !== undefined ? graphData : graphData?.data,
        colors: color !== null ? colors : defaultColor,
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                },
                point: {
                    events: {
                        click: function () {
                            if (this.indid)
                                handleIndicatorInNewPage(this.indid, graphTitle);
                        }
                    }
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors: color !== null ? colors : defaultColor,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.2f}%'
                },
                point: {
                    events: {
                        click: function () {
                            if (this.indid)
                                handleIndicatorInNewPage(this.indid, graphTitle);
                        }
                    }
                }
            },
            series: {
                cursor: 'pointer',
                colors: color !== null ? colors : defaultColor,
                colorByPoint: graphData.data?.length > 1 ? false : true,
                point: {
                    events: {
                        click: function () {
                            if (this.drilldown) {

                            }
                        }
                    }
                }
            }
        },
    };

    return (
        <div>
            <Suspense fallback={<div>Loading data...</div>}>
                {graphData ? (
                    <div className="text-center mb-0">
                        <strong>
                            {graphWaiting ? (
                                <div className="loader"></div>
                            ) : (
                                <div>
                                    <HighchartsReact highcharts={Highcharts} options={options} />
                                </div>
                            )}
                        </strong>
                    </div>
                ) : (
                    <div className="loader"></div>
                )}
            </Suspense>
        </div>
    );
};

export default ChartComponentIndicator;
