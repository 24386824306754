import React, { useEffect, useState } from 'react'
import SingleSeriesChart from './charts/SingleSeriesChart'
import MapChartComponent from './charts/MapChartComponent'
import axios from 'axios';
import useDefaultMonthYear from '../feature/useDefaultMonthYear';
import { API__LIVE_Link } from '../commons/Constant';
import { useParams } from 'react-router-dom';


const IndicatorDashboardComponent = (props) => {

    console.log('indicator dashboard component', props)
    const { year, formattedMonth } = useDefaultMonthYear();
    const [indicatorWaiting2, setIndicatorWaiting2] = useState(true);
    const [indicatorValue2, setIndicatorValue2] = useState({});
    const [parentState, setParentState] = useState('Hello from Parent!');
    const [districtwisedata, setDistrictwisedata] = useState({});
    const [subtitlegraph, setSubtitlegraph] = useState('');

    const parentFunction = (distcode) => {
        fetchindicatorData(distcode);

        if (distcode !== "undefined" && distcode !== undefined) {
            fetchindicatorData2(distcode);
        }
    };

    const { key } = useParams();
    const storedData = sessionStorage.getItem(key);
    // console.log(key)
    const data1 = storedData ? JSON.parse(storedData) : null;
    useEffect((distcode) => {

        if (distcode !== "undefined" && distcode !== undefined) {
            fetchindicatorData2(distcode);
        }

    }, [])

    const fetchindicatorData2 = (distcode) => {

        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province')
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;
        try {
            setIndicatorWaiting2(true)
            axios.get(`${API__LIVE_Link}get_indicator_data_monthwise.php?indid=${data1.data}${distQueryParam}${provQueryParam}&${option}=${value}&level=district&distcode=${distcode}`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setIndicatorValue2(response.data);
                        // console.log("Response data indicator...", response.data)
                        setIndicatorWaiting2(false);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setIndicatorWaiting2(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchindicatorData = (distcode) => {
        sessionStorage.setItem('distcode', distcode);
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;
        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const procode = sessionStorage.getItem('province');
        const distQueryParam = `${distcode ? `&distcode=${distcode}` : ''}`;
        const provQueryParam = `${procode ? `&procode=${procode}` : ''}`;
        // console.log('distcode', distcode)
        try {
            // axios.get(`${API__LIVE_Link}get_indicator_data_monthwise.php?${distQueryParam}${provQueryParam}&${option}=${value}&level=district`)
            // axios.get(`mapdrilldowndata.json&distcode=${distcode}`)
            axios.get(`${API__LIVE_Link}get_indicator_data_monthwise.php?indid=${data1.data}${distQueryParam}${provQueryParam}&${option}=${value}&level=district&distcode=${distcode}`)
                .then(response => {
                    if (response.data) {
                        setDistrictwisedata(response.data)
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {

                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };
    useEffect(() => {
    }, [districtwisedata])

    // console.log('props.data:', props)
    // console.log('selectIndicator value', indicatorValue2)
    return (
        <div className="row mt-2">
            <div className="col-lg-3">
                <div className="row">
                    <div className='col-lg-12 inddashboard-bar-col'>
                        {
                        props.data.indicator ? 
                        <SingleSeriesChart
                            idPrefix={"1"}
                            graphTitle={props.data.indicator}
                            graphWaiting={props.graphWaiting}
                            graphSubTitle={props.data.subtitle}
                            graphData={props.data.data[0].data}
                            chartType={"bar"}
                            series_type={"single"}
                            height={props.data.div_height}
                            is_percent={props.data.is_percent}
                        /> 
                        :
                            <div className="loader"></div>
                        }
                    </div>
                </div>
            </div>
            <div className="col-lg-5 p-0">
                <div className="inner-chart-box map-chart-component-height">
                    {props.mapdata.data ? <MapChartComponent
                        dataClasses={props.mapdata.dataclasses}
                        mapdata={props.mapdata.data}
                        title={props.mapdata.title}
                        subtitle={props.mapdata.subtitle}
                        childState={parentState}
                        height={2}
                        setChildState={setParentState}
                        childFunction={parentFunction}
                    /> :
                        <div className="loader"></div>
                    }
                </div>
            </div>
            <div className="col-lg-4 ">
                <div className='row'>
                    {/* <div className='col-lg-12 inddashboard-column-col'>
                        <SingleSeriesChart
                            idPrefix={"2"}
                            graphTitle={(indicatorValue2 && indicatorValue2.indicator) || props.data.indicator}
                            graphSubTitle={'Month wise'}
                            graphData={indicatorValue2.data || props.indicatordata.data}
                            chartType={"column"}
                            height={33}
                            series_type={"single"}
                            is_percent={props.indicatordata.is_percent}
                        />
                    </div> */}
                    {props.indicatordata2.data ? <SingleSeriesChart
                        idPrefix={"2"}
                        graphTitle={props.data.indicator}
                        graphSubTitle={props.indicatordata2.subtitle}
                        graphData={props.indicatordata2.data}
                        chartType={"line"}
                        height={1}
                        series_type={"single"}
                        is_percent={props.indicatordata2.is_percent}
                    /> :
                        <div className="loader"></div>
                    }
                    {/* {
                        indicatorValue2 && Object.keys(indicatorValue2).length > 0 ? <div className='col-lg-12 mt-2 inddashboard-column-col'>
                            <SingleSeriesChart
                                idPrefix={"2"}
                                graphTitle={indicatorValue2.indicator}
                                graphSubTitle={'Month wise'}
                                graphData={indicatorValue2.data}
                                chartType={"line"}
                                height={34}
                                series_type={"single"}
                                is_percent={props.indicatordata2.is_percent}
                            />
                        </div> : <SingleSeriesChart
                            idPrefix={"2"}
                            graphTitle={props.data.indicator}
                            graphSubTitle={'Month wise'}
                            graphData={props.indicatordata2.data}
                            chartType={"line"}
                            height={34}
                            series_type={"single"}
                            is_percent={props.indicatordata2.is_percent}
                        />
                    } */}
                </div>
                {
                    indicatorValue2 !== null && Object.keys(indicatorValue2).length !== 0 && (
                        <div className='row mt-2'>
                            <div className="col-lg-12 inddashboard-column-col">
                                {/* <SingleSeriesChart
                                    idPrefix={"2"}
                                    graphTitle={districtwisedata.title}
                                    graphSubTitle={districtwisedata.subtitle}
                                    graphData={districtwisedata.data}
                                    chartType={districtwisedata.chart_type}
                                    height={34}
                                    series_type={"single"}
                                    is_percent={null}
                                /> */}
                                <SingleSeriesChart
                                    idPrefix={"2"}
                                    graphTitle={indicatorValue2.indicator}
                                    graphSubTitle={indicatorValue2.subtitle}
                                    graphData={indicatorValue2.data}
                                    chartType={"line"}
                                    height={1}
                                    series_type={"single"}
                                    is_percent={props.indicatordata2.is_percent}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </div >
    )
}

export default IndicatorDashboardComponent