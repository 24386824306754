
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Slider from '../components/Slider';
import { useLocation } from 'react-router-dom';
import DynamicRowChartComponent from '../components/DynamicRowChartComponent';
import axios from 'axios';
import { API__LIVE_Link } from '../commons/Constant';
import SingleRowComponent from '../components/SingleRowComponent';
import colors from '../components/colors';

const DashboardPage = ({ onUrlChange, dashboard_id }) => {
    let i = 0;
    const location = useLocation();
    const currentUrl = location.pathname;
    const [data, setData] = useState([]);
    const [tabdata, setTabData] = useState([])
    const [increment, setIncrement] = useState(0)
    const [cardslayout, setCardslayout] = useState('')
    const [sourceData, setSourceData] = useState([])
    // const [dashboard_id, setDashboardid] = useState(localStorage.getItem('dashboardid') || '1')
    const [headercards, setHeaderCards] = useState([]);
    const [cardWaiting, setCardWaiting] = useState(false);
    const [GraphWaiting, setGraphWaiting] = useState(false)
    const [option, setOption] = useState('')
    const [condition, setCondition] = useState(true)
    const currentDate = new Date();
    // const formattedMonth = (currentDate.getMonth() - 2).toString().padStart(2, '0');
    const [sessionStorageValue, setSessionStorageValue] = useState();
    const [url, seturl] = useState();
    const filterValue = sessionStorage.getItem('filter');
    const filterChangeCountRef = useRef(0);
    const distcode = sessionStorage.getItem('distcode');
    const procode = sessionStorage.getItem('province')
    useEffect(() => {
        const disableBackButton = () => {

            window.history.pushState(null, null, window.location.href);
        };

        window.addEventListener('popstate', disableBackButton);
        return () => {
            window.removeEventListener('popstate', disableBackButton);
        };
    }, []);

    const gettabdata = () => {
        axios.get('tabdata.json')
            .then(response => {
                if (response.data) {
                    setTabData(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.log("Error Display");
            });
    }


    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    let year;
    let month;

    if (currentMonth <= 4) {
        // If the current month is January to April, set to the previous year
        year = currentYear - 1;
        month = currentMonth + 8; // Set to the corresponding month 4 months ago
    } else {
        year = currentYear;
        month = currentMonth - 4; // Set to the corresponding month 4 months ago
    }

    // Adjust to handle negative month values
    if (month <= 0) {
        month += 12; // Wrap around to December of the previous year
    }

    // Ensure that the month is represented with two digits
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const fetchCardData = () => {

        // const value = sessionStorage.getItem('filter') || '202306'
        const value = sessionStorage.getItem('filter') || `${year}${formattedMonth}`;

        const option = sessionStorage.getItem('selectedOption') || 'fmonth'
        const prov = sessionStorage.getItem('province')
        const dist = sessionStorage.getItem('distcode')
        console.log("Province and District", prov, " : ", dist)
        const distQueryParam = `${dist ? `&distcode=${dist}` : ''}`;
        const provQueryParam = `${dist ? `&procode=${prov}` : ''}`;

        try {
            setCardWaiting(true)
            axios.get(`${API__LIVE_Link}get_dashboard_cards.php?dashboard_id=${dashboard_id}&${option}=${value}${distQueryParam}${provQueryParam}`)
                // axios.get('cardsData.json')

                .then(response => {
                    // Handle successful response
                    if (response.data) {
                        setHeaderCards(response.data);
                        setCardWaiting(false);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                })
                .finally(() => {
                    setCardWaiting(false);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    window.addEventListener('userLoggedIn', () => {
        localStorage.setItem('isLoggedIn', 'true');
    });
    const fetchData = async () => {
        try {
            axios.get(`${API__LIVE_Link}get_dashboard_configuration.php?dashboard_id=${dashboard_id}`)

                .then(response => {
                    if (response.data.sections) {
                        setData(response.data.sections);
                        setCardslayout(response.data.cards_layout);
                        setIncrement(0);
                        gettabdata();
                    }
                })
                .catch(error => {

                    console.error('Error fetching data:', error);
                    console.log("Error Display");
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };
    const resetStates = () => {
        setTabData([]);
        setIncrement(0);
        setData([]);
        setSourceData([]);
    };
    useEffect(() => {
        const currentUrl = location.pathname;
        if (currentUrl !== sessionStorageValue) {
            onUrlChange(currentUrl);
            resetStates();
            fetchData();
            fetchCardData();
            setSessionStorageValue(currentUrl);
        }
    }, [location.pathname]);

    // useEffect(() => {
    //     setSourceData1();
    // }, [data]);
    useEffect(() => {
        // Check if this is the first change to filterValue
        if (filterChangeCountRef.current === 0) {
            // Increment the change count
            filterChangeCountRef.current++;
            return;
        }

        // If it's not the first change, run the effect
        fetchCardData();

    }, [filterValue, distcode, procode]);

    return (
        <div className='dashboard-page'>
            <Slider cardWaiting={cardWaiting} cardData={headercards} cardslayout={cardslayout} colors={colors} />          
                {
                    data?.map((item, index) => (
                        <>                  
                            {item.component_design === "DynamicRowComponent" ?
                            <DynamicRowChartComponent
                                key={index}
                                dataObject={item}
                                tabdata={tabdata}
                                GraphWaiting={GraphWaiting}
                            /> : 
                            <SingleRowComponent
                                key={index}
                                dataObject={item}
                                tabdata={tabdata}
                                GraphWaiting={GraphWaiting}
                            />
                            }
                        </> 
                    )) 
                }
        </div>
    )
}
export default DashboardPage