import React, { useEffect, useRef, useState } from 'react'
import SunburstChartComponent from './charts/SunburstChartComponent';
import SingleSeriesChart from './charts/SingleSeriesChart';
import TreeChartComponent from './charts/TreeChartComponent';
import MapChartComponent from './charts/MapChartComponent';
import DataTable from 'react-data-table-component';
import TableComponentJquery1 from './tableComponent/TableComponentJquery1';
import TableComponentJquery2 from './tableComponent/TableComponentJquery2';
import ChartComponentIndicator from './charts/ChartComponentIndicator';
import ClusterMapComponent from './charts/ClusterMapComponent';
import BubbleMapComponent from './charts/BubbleMapComponent';

const LandingNewComponent = ({ dataObject, graphWaiting }) => {

    const [selectedIcon, setSelectedIcon] = useState('graph-icon');

    const columns = [
        {
            name: 'Commodity Items',
            selector: (row) => row.item,
            sortable: true,
        },
        {
            name: 'Received',
            selector: (row) => row.received,
            sortable: true,
        },
        {
            name: 'Distributed',
            selector: (row) => row.dist,
            sortable: true,
        },
        {
            name: 'Consumed',
            selector: (row) => row.consumed,
            sortable: true,
        }
    ];
    useEffect(() => {

    }, [dataObject])

    return (
        <div className="row mt-2">

            <div className="col-lg-12">
                <span
                    className={`icon-tab ${selectedIcon === 'graph-icon' ? 'active' : ''} graph-icon`}
                    onClick={() => setSelectedIcon('graph-icon')}
                >
                    <i className="bi bi-bar-chart-line"></i>
                </span>
                <span
                    className={`icon-tab ${selectedIcon === 'table-icon' ? 'active' : ''} table-icon`}
                    onClick={() => setSelectedIcon('table-icon')}
                >
                    <i className="bi bi-table"></i>
                </span>
            </div>

            <div className="col-lg-3 pe-0">
                <div className="row">
                    {dataObject && dataObject.source_data && dataObject.source_data.length > 0 && dataObject.source_data.map((sourceData, index) => (
                        <>
                            {/* {console.log('sourceData test', sourceData.data)} */}
                            {sourceData.component_design_column === "1" ? (
                                <div className="col-lg-12" key={index}>
                                    <div className="inner-box-chart column3-chart chart-3x">
                                        <>
                                            {sourceData.chart_type === "sunburst" ? (
                                                <>
                                                    {/* <SunburstChartComponent
                                                        data={sourceData?.data}
                                                        height={sourceData?.div_height}
                                                        graphTitle={sourceData?.title}
                                                        graphSubTitle={sourceData?.subtitle}
                                                        graphWaiting={graphWaiting}
                                                    /> */}


                                                    {/*  */}

                                                    {selectedIcon === 'graph-icon' ? (
                                                        <>
                                                            <SunburstChartComponent
                                                                data={sourceData?.data}
                                                                height={sourceData?.div_height}
                                                                graphTitle={sourceData?.title}
                                                                graphSubTitle={sourceData?.subtitle}
                                                                graphWaiting={graphWaiting}
                                                            />
                                                        </>
                                                    ) : selectedIcon === 'table-icon' ? (
                                                        <TableComponentJquery2
                                                            sourceData={sourceData}
                                                            GraphWaiting={graphWaiting}
                                                            dataObject={dataObject}
                                                            idPrefix={dataObject.idPrefix}
                                                            col12={'single'}
                                                        />
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </>
                                            ) : sourceData.chart_type === "datatable" ? (
                                                <DataTable columns={columns} data={sourceData.data} fixedHeader fixedHeaderScrollHeight="500px" responsive pointerOnHover striped />
                                            ) : sourceData.chart_type === "map" ? (
                                                <MapChartComponent dataclasses={sourceData?.data?.dataclasses} mapdata={sourceData?.data} title={sourceData?.data?.title} subtitle={sourceData?.data?.subtitle} checkChildEvent={false} height={sourceData?.div_height} />
                                            ) : sourceData.chart_type === "treemap" ? (
                                                <TreeChartComponent treedata={sourceData?.data} title={sourceData?.title} subtitle={sourceData?.subtitle} causeName1={sourceData?.causeName} color={sourceData?.colors} />
                                            ) : (
                                                // <SingleSeriesChart
                                                //     idPrefix={index}
                                                //     graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                                //     graphWaiting={graphWaiting}
                                                //     graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                                //     graphData={sourceData.series_type === 'single' ? sourceData.data : sourceData.series_type === 'multi' ? sourceData.data.data : null}
                                                //     chartType={sourceData.chart_type && sourceData.chart_type}
                                                //     series_type={sourceData.series_type}
                                                //     height={sourceData?.div_height}
                                                //     landingpagecomponent={true}
                                                // />
                                                selectedIcon === 'graph-icon' ? (
                                                    <ChartComponentIndicator
                                                        idPrefix={index}
                                                        graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                                        graphWaiting={graphWaiting}
                                                        graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                                        graphData={sourceData.series_type === 'single' ? sourceData : sourceData.series_type === 'multi' ? sourceData.data : null}
                                                        chartType={sourceData.chart_type && sourceData.chart_type}
                                                        series_type={sourceData.series_type}
                                                        height={sourceData?.div_height}
                                                        landingpagecomponent={true}
                                                        color={sourceData?.colors}
                                                    />

                                                ) : selectedIcon === 'table-icon' ? (
                                                    <TableComponentJquery2
                                                        sourceData={sourceData}
                                                        GraphWaiting={graphWaiting}
                                                        dataObject={dataObject}
                                                        idPrefix={dataObject.idPrefix}
                                                        col12={'single'}
                                                    />
                                                ) : (
                                                    <div></div>
                                                )
                                            )}
                                        </>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ))}
                </div>
            </div>
            <div className="col-lg-4 p-0">
                <div className="inner-chart-box map-chart-component-height">
                    {dataObject && dataObject.source_data && dataObject.source_data.length > 0 && dataObject.source_data.map((sourceData, index) => (
                        <>
                            {sourceData.component_design_column === "2" ? (
                                <div className="col-lg-12" key={index}>
                                    <div className="inner-box-chart column3-chart chart-3x">
                                        <>
                                            {/* {console.log('checking map--', sourceData)} */}
                                            {sourceData.chart_type === "sunburst" ? (
                                                <>
                                                    <SunburstChartComponent
                                                        data={sourceData?.data}
                                                        height={sourceData?.div_height}
                                                        graphTitle={sourceData?.title}
                                                        graphSubTitle={sourceData?.subtitle}
                                                        graphWaiting={graphWaiting}
                                                    />
                                                </>
                                            ) : sourceData.chart_type === "datatable" ? (
                                                <DataTable columns={columns} data={sourceData.data} fixedHeader fixedHeaderScrollHeight="500px" responsive pointerOnHover striped />
                                            ) : sourceData.chart_type === "map" ? (
                                                <MapChartComponent dataclasses={sourceData?.data?.dataclasses} mapdata={sourceData?.data?.data} title={sourceData?.data?.title} subtitle={sourceData?.data?.subtitle} checkChildEvent={false} height={sourceData?.div_height} isloading={sourceData?.isloading} />
                                            ) : sourceData.chart_type === "treemap" ? (
                                                <TreeChartComponent treedata={sourceData?.data} title={sourceData?.title} subtitle={sourceData?.subtitle} causeName1={sourceData?.causeName} height={sourceData?.div_height} color={sourceData?.colors} />
                                            ) : (
                                                <SingleSeriesChart
                                                    idPrefix={index}
                                                    graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                                    graphWaiting={graphWaiting}
                                                    graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                                    graphData={sourceData.series_type === 'single' ? sourceData.data : sourceData.series_type === 'multi' ? sourceData.data.data : null}
                                                    chartType={sourceData.chart_type && sourceData.chart_type}
                                                    series_type={sourceData.series_type}
                                                    height={sourceData?.div_height}
                                                    landingpagecomponent={true}
                                                />
                                            )}
                                        </>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ))}
                    <div className="inner-box-chart">
                        <BubbleMapComponent />
                    </div>
                </div>
            </div>
            <div className="col-lg-5 ps-0">
                <div className="row">
                    {dataObject && dataObject.source_data && dataObject.source_data.length > 0 && dataObject.source_data.map((sourceData, index) => (
                        <>
                            {sourceData.component_design_column === "3" ? (
                                <div className="col-lg-12" key={index}>
                                    <div className="inner-box-chart">
                                        <>

                                            {sourceData.chart_type === "sunburst" ? (
                                                <>
                                                    <SunburstChartComponent
                                                        data={sourceData?.data}
                                                        height={sourceData?.div_height}
                                                        graphWaiting={graphWaiting}
                                                        graphTitle={sourceData?.title}
                                                        graphSubTitle={sourceData?.subtitle}
                                                    />
                                                </>
                                            ) : sourceData.chart_type === "datatable" ? (
                                                <DataTable columns={columns} data={sourceData.data} fixedHeader fixedHeaderScrollHeight="500px" responsive pointerOnHover striped height={sourceData?.div_height} />
                                            ) : sourceData.chart_type === "map" ? (
                                                <MapChartComponent dataclasses={sourceData?.data?.dataclasses} mapdata={sourceData?.data} title={sourceData?.data?.title} subtitle={sourceData?.data?.subtitle} checkChildEvent={false} height={sourceData?.div_height} />

                                            ) : sourceData.chart_type === "treemap" ? (
                                                <TreeChartComponent treedata={sourceData?.data} title={sourceData?.data?.title} subtitle={sourceData?.data?.subtitle} causeName1={sourceData?.data?.causeName} height={sourceData?.div_height} color={sourceData?.colors} />

                                            ) : (
                                                <ChartComponentIndicator
                                                    idPrefix={index}
                                                    graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                                    graphWaiting={graphWaiting}
                                                    graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                                    graphData={sourceData.series_type === 'single' ? sourceData.data : sourceData.series_type === 'multi' ? sourceData.data.data : null}
                                                    chartType={sourceData.chart_type && sourceData.chart_type}
                                                    series_type={sourceData.series_type}
                                                    height={sourceData?.div_height}
                                                    landingpagecomponent={true}
                                                />

                                            )}
                                        </>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ))}
                </div>
                <div className="inner-box-chart">
                    <ClusterMapComponent />
                </div>
            </div>
        </div >
    )
}

export default LandingNewComponent