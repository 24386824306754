import { faStarHalfStroke } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const SingleActionComponent3 = ({ props, color }) => {
    const [showModal, setShowModal] = useState(false);

    console.log('color', color)
    const spanClass =
        parseInt(props.value, 10) > 70
            ? 'text-light'
            : parseInt(props.value, 10) > 40
                ? 'text-light'
                : 'text-light';

    const stringWithSpan = props.indicator_description.replace(
        /get_value/g,
        ``
    );

    const handleModalToggle = () => {
        setShowModal(!showModal);
    };

    return (
        <div className='accordion-body signle__action__component3' onClick={handleModalToggle} style={{
            borderColor: parseInt(props.value, 10) > 70
                ? '#ffffff'
                : parseInt(props.value, 10) > 40
                    ? '#ffffff'
                    : '#ffffff'
        }}>

            <div style={{ background: `${color}` }}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className={`ms-4 `}>
                        {parseInt(props.value, 10) > 70 ? (
                            <FontAwesomeIcon icon={faThumbsUp} bounce title='Good' className='me-2' style={{ color: '#ffffff' }} />
                        ) : parseInt(props.value, 10) > 40 ? (
                            <FontAwesomeIcon icon={faStarHalfStroke} title='Average' flip className='me-2' style={{ color: '#ffffff' }} />
                        ) : (
                            <FontAwesomeIcon icon={faExclamationTriangle} beatFade title='Bad' className='me-2' style={{ color: '#ffffff' }} />
                        )}
                    </div>
                    <p className="text-end mb-0 mt-3"><h2 class="figure-number text-light">{props.value}</h2></p>
                </div>
                <p className="title text-end text-light" dangerouslySetInnerHTML={{ __html: stringWithSpan }}></p>
            </div>


            {props.is_percent == 1 ? (
                <div className="pb-1 mx-2">
                    <div className="progress">
                        <div
                            className={`progress-bar ${parseInt(props.value, 10) > 70
                                ? 'bg-success'
                                : parseInt(props.value, 10) > 40
                                    ? 'bg-warning'
                                    : 'bg-danger'
                                }`}
                            role="progressbar"
                            style={{ width: props.value }}
                            aria-valuenow={props.value}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {props.value}
                        </div>
                    </div>
                </div>
            ) : (
                <span></span>
            )}
            {showModal && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header py-1">
                                <p className="modal-title"><span dangerouslySetInnerHTML={{ __html: stringWithSpan }}></span> - Details</p>
                                <button type="button" className="btn btn-danger close" data-dismiss="modal" aria-label="Close" onClick={handleModalToggle}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <p>{props.link}</p> */}
                                <iframe src={props.link} width="100%" height="500" frameborder="0" title={"title"}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Add the modal-backdrop class */}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};

export default SingleActionComponent3