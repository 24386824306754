import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultIconComponent from './DefaultIconComponent';


const AsideBarComponent = (data,onLogout, alertFun) => {

    // const data = {
    //     "data": [
    //         {
    //             "id": "8",
    //             "page": "Action Dashboard",
    //             "description": "Action Dashboard",
    //             "url_prefix": "/actiondashboard",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "megaphoneIcon",
    //             "default_option": null
    //         },
    //         {
    //             "id": "1",
    //             "page": "Home",
    //             "description": "Health indicators",
    //             "url_prefix": "/",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "FpIcon",
    //             "default_option": null
    //         },
    //         {
    //             "id": "2",
    //             "page": "Landing",
    //             "description": "Landing Page",
    //             "url_prefix": "/landingPage",
    //             "page_component": "LandingPage",
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "LogoIcon",
    //             "default_option": null
    //         },
    //         {
    //             "id": "3",
    //             "page": "Family Planning",
    //             "description": "Family Planning",
    //             "url_prefix": "/familyplanning",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "FpIcon",
    //             "default_option": "fpservices"
    //         },
    //         {
    //             "id": "7",
    //             "page": "Mother & Child Health",
    //             "description": "Mother & Child Health",
    //             "url_prefix": "/motherchild",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "MchIcon",
    //             "default_option": "anc"
    //         },
    //         {
    //             "id": "5",
    //             "page": "Infectious Diseases",
    //             "description": "Infectious Diseases",
    //             "url_prefix": "/infectiousdiseases",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "DiseasesIcon",
    //             "default_option": "incidence"
    //         },
    //         {
    //             "id": "4",
    //             "page": "Immunization",
    //             "description": "Immunization",
    //             "url_prefix": "/immunization",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "ImmunizationIcon",
    //             "default_option": "imz"
    //         },
    //         {
    //             "id": "6",
    //             "page": "Nutrition",
    //             "description": "Nutrition",
    //             "url_prefix": "/nutrition",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "NutrationIcon",
    //             "default_option": "undernutrition"
    //         },
    //         {
    //             "id": "12",
    //             "page": "HR",
    //             "description": "Human Resource",
    //             "url_prefix": "/hr",
    //             "page_component": null,
    //             "topbar_dropdown_optionname": "",
    //             "dashboard_icon": "HR",
    //             "default_option": null,
    //             "child": [
    //                 {"id": "121",
    //                 "page": "ChildHR",
    //                 "description": "Human Resource",
    //                 "url_prefix": "/childhr",
    //                 "page_component": null,
    //                 "topbar_dropdown_optionname": "",
    //                 "dashboard_icon": "NutrationIcon",
    //                 "default_option": "undernutrition"}
    //             ]
    //         }
    //     ],
    //     "currentUrl": "/nutrition"
    // }
    const location = useLocation();
    const currentUrl = location.pathname;

    console.log('data-', data)

    const [isColorDivVisible, setColorDivVisible] = useState(false);
    const toggleDiv = () => {
        setColorDivVisible(!isColorDivVisible);
    };

    const handleLogout = () => {
        console.log("Logging out..."); // Debugging statement
        window.localStorage.clear();

        if (typeof onLogout === 'function') {
            onLogout(); // Check if onLogout is a valid function
        } else {
            console.error("onLogout is not a valid function.");
        }
    };

    useEffect(() => {
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue);
            element.classList = '';
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }, [])
    const colorThemePrimary = () => {

        localStorage.setItem('ids_color', 'primary-color')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];
            const colorValue = localStorage.getItem('ids_color');
            if (colorValue === 'blue') {
                newClasses.push('blue');
            }
            else {
                newClasses.push('primary-color')
            }
            element.classList = '';
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const colorBlue = () => {
        localStorage.setItem('ids_color', 'blue')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];

            // Retrieve the color value from local storage
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue)

            // Remove all existing classes
            element.classList = '';

            // Add the new classes
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const colorGreen = () => {
        localStorage.setItem('ids_color', 'green')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];

            // Retrieve the color value from local storage
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue)


            // Remove all existing classes
            element.classList = '';

            // Add the new classes
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const colorYellow = () => {
        localStorage.setItem('ids_color', 'yellow')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];

            // Retrieve the color value from local storage
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue)


            // Remove all existing classes
            element.classList = '';

            // Add the new classes
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const colorPink = () => {
        localStorage.setItem('ids_color', 'pink')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];

            // Retrieve the color value from local storage
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue)


            // Remove all existing classes
            element.classList = '';

            // Add the new classes
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const colorOrange = () => {
        localStorage.setItem('ids_color', 'orange')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];

            // Retrieve the color value from local storage
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue)


            // Remove all existing classes
            element.classList = '';

            // Add the new classes
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const colorGray = () => {
        localStorage.setItem('ids_color', 'gray')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];

            // Retrieve the color value from local storage
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue)


            // Remove all existing classes
            element.classList = '';

            // Add the new classes
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const colorDark = () => {
        localStorage.setItem('ids_color', 'dark')
        const element = document.getElementById('main-body');
        if (element) {
            const newClasses = ['app', 'main-wrapper'];

            // Retrieve the color value from local storage
            const colorValue = localStorage.getItem('ids_color');
            newClasses.push(colorValue)


            // Remove all existing classes
            element.classList = '';

            // Add the new classes
            newClasses.forEach((classValue) => {
                element.classList.add(classValue);
            });
        }
    }

    const currentDate = new Date();
    const formattedMonth = (currentDate.getMonth() - 2).toString().padStart(2, '0');
    const currentYear = currentDate.getFullYear();
    const formattedDate = `${currentYear}${formattedMonth}`;


    return (
        <aside data-aos="fade-right">
            {/* <div className='logo'>
                <a href='#/landingPage' className='logo-svg' title='Integrated Dashboard Sindh'>
                    <LogoIcon />
                </a>
                <span className='text-light mt-1 d-block text-center w-100' title='Integrated Dashboard Sindh'>  IDS</span>
            </div> */}
            <div className='div-link'>
                {/* <a href='#/actiondashboard' id='8' className='link'>
                    <span className='text'>Action Dashboard</span>
                    <span className='icon'>
                        <img src={megaphoneIcon} alt="" style={{width:"24px"}} />
                    </span>
                </a> */}
                {data.data.map(item => (
                    // <a href={`#${item.url_prefix}`} id={item.id} className='link'>
                    //     <span className='text'>{item.page}</span>
                    //     <span className='icon'>
                    //         {/* <FpIcon /> */}
                    //         <img src={require(`../assets/icons/menu/${item.dashboard_icon}.svg`)} alt="" />
                    //     </span>
                    // </a>
                    <div className='sidebar-link-data'>
                        <span

                            className={`link ${currentUrl === item.url_prefix ? 'active-menu' : ''}`}
                        >

                            <span className='icon'>
                                {/* <FpIcon /> */}
                                {/* <img src={require(`../assets/icons/menu/${item.dashboard_icon}.svg`)} alt="" /> */}
                                <DefaultIconComponent item={item} />
                            </span>
                        </span>
                        <span className='text'><a href={`#${item.url_prefix}`}
                            id={item.id}>{item.page}</a></span>
                        <ul>
                            {item.child !== undefined && item.child.length > 0 && item.child.map((childItem, index) => (
                                <li key={index}>
                                    <a href={`#${childItem.url_prefix}`} style={{ display: 'block', textAlign: 'left' }}>{childItem.page}</a>
                                </li>
                            ))}
                        </ul>

                    </div>
                ))}

            </div>
            <div className='bottom-links' style={{ marginTop: "5%" }}>
                <ul>
                    <li>
                        <a href=''>
                            <i className="bi bi-person-circle text-light"></i>
                        </a>
                    </li>
                    <li>
                        <a href=''>
                            <i className="bi bi-patch-question text-light"></i>
                        </a>
                    </li>
                    <li onClick={toggleDiv}>
                        <span>
                            <i className="bi bi-palette text-light"></i>
                        </span>
                        {isColorDivVisible &&
                            <div className='color-div'>
                                <div className="d-flex justify-content-between">
                                    <div className="blue-theme circle-div circle-bg-primary-color" onClick={colorThemePrimary}>

                                    </div>
                                    <div className="blue-theme circle-div circle-bg-blue-color" onClick={colorBlue}>

                                    </div>
                                    <div className="blue-theme circle-div circle-bg-green-color" onClick={colorGreen}>

                                    </div>
                                    <div className="blue-theme circle-div circle-bg-yellow-color" onClick={colorYellow}>

                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="blue-theme circle-div circle-bg-pink-color" onClick={colorPink}>

                                    </div>
                                    <div className="blue-theme circle-div circle-bg-orange-color" onClick={colorOrange}>

                                    </div>
                                    <div className="blue-theme circle-div circle-bg-gray-color" onClick={colorGray}>

                                    </div>
                                    <div className="blue-theme circle-div circle-bg-dark-color" onClick={colorDark}>

                                    </div>
                                </div>
                            </div>
                        }
                    </li>
                    <li>
                        <a href=''>
                            <i className="bi bi-gear text-light"></i>
                        </a>
                    </li>
                    <li>
                        <a href='' onClick={handleLogout}>
                            <i className="bi bi-power text-light"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </aside>
    )
}

export default AsideBarComponent