

export default function getDistrictNameFromCode(code) {
    switch (code) {
        case "211":
            return "Badin";
        case "212":
            return "Dadu";
        case "213":
            return "Hyderabad";
        case "214":
            return "Sujawal";
        case "215":
            return "Jamshoro";
        case "216":
            return "Tando Allahyar";
        case "217":
            return "Thatta";
        case "218":
            return "Matiari";
        case "219":
            return "Tando Muhammad Khan";
        case "221":
            return "Karachi West";
        case "222":
            return "Karachi East";
        case "223":
            return "Korangi";
        case "224":
            return "Karachi Central";
        case "225":
            return "Karachi South";
        case "226":
            return "Malir";
        case "231":
            return "Jacobabad";
        case "232":
            return "Larkana";
        case "233":
            return "Shikarpur";
        case "234":
            return "Kamber";
        case "235":
            return "Kashmore";
        case "241":
            return "Khairpur";
        case "242":
            return "Naushero Feroze";
        case "243":
            return "Shaheed Benazirabad";
        case "244":
            return "Sukkur";
        case "245":
            return "Ghotki";
        case "251":
            return "Mirpurkhas";
        case "252":
            return "Sanghar";
        case "253":
            return "Tharparkar";
        case "254":
            return "Umerkot";
        default:
            return '';
    }
}
