import React from 'react'

import SunburstChartComponent from './charts/SunburstChartComponent'
import TabsComponent from './TabsComponent';
import ChartComponentIndicator from './charts/ChartComponentIndicator';
import TreeChartComponent from './charts/TreeChartComponent';

const RowChartComponent = ({ sourceData, GraphWaiting, dataObject, idPrefix, data, col12 }) => {
    return (
        <div className={col12 === 'single' ? 'col-lg-12' : 'col-lg-9'}>
            <div className='row center-column-padding-0'>
                {sourceData?.source_data?.map((sourceData, sourceIndex) => (
                    <div className={`col-lg-${sourceData.div_width}`}>
                        {/* {console.log('sourceData', sourceData)} */}
                        <div className="inner-box-chart" key={sourceIndex}>
                            {sourceData.data.length === 0 ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    {sourceData.chart_type === "sunburst" ? (
                                        <SunburstChartComponent
                                            data={sourceData.data}
                                        />
                                    ) : sourceData.chart_type === "treemap" ? (
                                        <TreeChartComponent
                                            data={sourceData.data}
                                            color={sourceData.colors || 1}
                                        />
                                    ) : (
                                        sourceData.is_tabbed === "false" ?
                                            <ChartComponentIndicator
                                                graphWaiting={sourceData.isloading ? sourceData.graphSubTitle : GraphWaiting}
                                                idPrefix={dataObject.idPrefix}
                                                graphTitle={`${sourceData.title && sourceData.title} (${sourceData.source && sourceData.source})`}
                                                graphSubTitle={`${sourceData.subtitle && sourceData.subtitle}`}
                                                graphData={sourceData.series_type === "multi" ? sourceData.data : sourceData.data.data}
                                                chartType={sourceData.chart_type && sourceData.chart_type}
                                                series_type={sourceData.series_type}
                                                is_percent={sourceData.data.is_percent}
                                                height={sourceData.div_height}
                                                color={sourceData.colors}
                                            />
                                            : <TabsComponent idPrefix={idPrefix} data={sourceData.data } />
                                    )}
                                </>

                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default RowChartComponent