import React, { useEffect, useState } from 'react';
import {
  CircularProgressbar,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const Circle5Component = ({ percent, colors }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setPercentage(percent);
  }, [percent]);

  const circularProgressbarStyles = buildStyles({
    path: {
      stroke: colors ,
    },
    text: {
      fill: colors,
      fontSize: '16px',
    },
    rotation: 0.5 + (1 - percentage / 100) / 2
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '70px' }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={circularProgressbarStyles}
        />
      </div>
    </div>
  );
};

export default Circle5Component;
