import React, { useState, useEffect } from 'react'
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Circle1Component = ({ percent, colors }) => {

    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setPercentage(percent);
    }, [percent]);

    const circularProgressbarStyles = {
        path: {
            stroke: colors,
        },
        text: {
            fill: colors,
            fontSize: '16px',
        },
    };

    return (
        <div style={{ "display": "flex", "justifyContent": "center", "paddingTop": "5px" }}>
            <div style={{ width: "70px", "borderColor": "red" }}>
                <CircularProgressbar value={percentage} text={`${percentage}%`} styles={circularProgressbarStyles} />
            </div>
        </div>
    )
}

export default Circle1Component