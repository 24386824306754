import React, { useEffect, useState } from 'react'
import RadioButtonComponent from './RadioButtonComponent'
import axios from 'axios';
import { API__LIVE_Link } from '../commons/Constant';
import { useLocation } from 'react-router-dom';

const FilterComponent = ({ isActive, toggleClass, onUrlChange }) => {


    // const [selectedOption, setSelectedOption] = useState(null);
    const [active, setActive] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [dashboardid, setDashboardid] = useState('')
    const location = useLocation();
    const currentUrl = location.pathname;
    const toggleActivity = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionChange = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedOption(newSelectedOption);
        if (newSelectedOption === 'annual') {
            setSelectedQuarter('');
            setSelectedMonth('');
            setActive('disabled');

        } else {
            setActive();
        }
        if (newSelectedOption === 'quarterly') {
            setSelectedQuarter('1');
            setSelectedMonth('');
        }
        if (newSelectedOption === 'monthly') {
            setSelectedQuarter('');
            setSelectedMonth(sessionStorage.getItem('month') || currentDate.getMonth());

        }

    };

    // 
    const currentDate = new Date();
    let formattedMonth;

    if (currentDate.getMonth() < 4) {
        // If the current month is January to April, go back 4 months and to the previous year
        const lastYear = currentDate.getFullYear() - 1;
        formattedMonth = (currentDate.getMonth() + 8).toString().padStart(2, '0'); // Adding 8 to handle going back 4 months
    } else {
        // For other months, simply go back 4 months
        formattedMonth = (currentDate.getMonth() - 3).toString().padStart(2, '0'); // Subtracting 3 instead of 4 to handle single-digit months
    }

    const isJanuaryOrFebruary = currentDate.getMonth() < 4;

    const selectedYear1 = isJanuaryOrFebruary
        ? currentDate.getFullYear() - 1 // Previous year for January to April
        : currentDate.getFullYear();

    const finalValue = `${selectedYear1}${formattedMonth}`;

    // console.log('finalValue', finalValue);
    const defaultProvince = "2";
    // const defaultDistrict = '244';
    const defaultDistrict = '';

    // console.log('formattedMonth', currentDate)
    const [selectedProvince, setSelectedProvince] = useState(defaultProvince);
    const [selectedDistrict, setSelectedDistrict] = useState(defaultDistrict);
    const [selectedYear, setSelectedYear] = useState(selectedYear1);
    const [selectedMonth, setSelectedMonth] = useState(formattedMonth)
    const [selectedQuarter, setSelectedQuarter] = useState('');
    const [selectedOption, setSelectedOption] = useState('monthly');
  
    // console.log('Selected Month', selectedMonth);
    // console.log('Selected District', selectedDistrict);


  
    useEffect(() => {
        const defaultDistrictForProvince = getDefaultDistrictForProvince(selectedProvince);
        setSelectedDistrict(defaultDistrictForProvince);
    }, [selectedProvince]);

    const provinceDistricts = {
        AllProvince: [
            { "procode": "", "name": "All Provinces" },
            { "procode": "1", "name": "Punjab" },
            { "procode": "2", "name": "Sindh" },
            { "procode": "3", "name": "Khyber PakhtunKhwa" },
            { "procode": "4", "name": "Balochistan" },
            { "procode": "5", "name": "AJK" },
            { "procode": "6", "name": "Gilgit Baltistan" },
            { "procode": "7", "name": "Islamabad" },
            { "procode": "8", "name": "Merged Areas KP" }
        ],
        all: [
            { "distcode": "", "name": "All Provinces" },
        ],
        Sindh: [
            { "distcode": "", "name": "All Districts" },
            { "distcode": "211", "name": "Badin" },
            { "distcode": "212", "name": "Dadu" },
            { "distcode": "213", "name": "Hyderabad" },
            { "distcode": "214", "name": "Sujawal" },
            { "distcode": "215", "name": "Jamshoro" },
            { "distcode": "216", "name": "Tando Allahyar" },
            { "distcode": "217", "name": "Thatta" },
            { "distcode": "218", "name": "Matiari" },
            { "distcode": "219", "name": "Tando Muhammad Khan" },
            { "distcode": "221", "name": "Karachi West" },
            { "distcode": "222", "name": "Karachi East" },
            { "distcode": "223", "name": "Korangi" },
            { "distcode": "224", "name": "Karachi Central" },
            { "distcode": "225", "name": "Karachi South" },
            { "distcode": "226", "name": "Malir" },
            { "distcode": "231", "name": "Jacobabad" },
            { "distcode": "232", "name": "Larkana" },
            { "distcode": "233", "name": "Shikarpur" },
            { "distcode": "234", "name": "Kamber" },
            { "distcode": "235", "name": "Kashmore" },
            { "distcode": "241", "name": "Khairpur" },
            { "distcode": "242", "name": "Naushero Feroze" },
            { "distcode": "243", "name": "Shaheed Benazirabad" },
            { "distcode": "244", "name": "Sukkur" },
            { "distcode": "245", "name": "Ghotki" },
            { "distcode": "251", "name": "Mirpurkhas" },
            { "distcode": "252", "name": "Sanghar" },
            { "distcode": "253", "name": "Tharparkar" },
            { "distcode": "254", "name": "Umerkot" }
        ],
        Balochistan: [
            { "distcode": "", "name": "All Districts" },
            { "distcode": "411", "name": "Kalat" },
            { "distcode": "412", "name": "Kharan" },
            { "distcode": "413", "name": "Khuzdar" },
            { "distcode": "414", "name": "Lasbella" },
            { "distcode": "415", "name": "Mastung" },
            { "distcode": "416", "name": "Awaran" },
            { "distcode": "417", "name": "Washuk" },
            { "distcode": "418", "name": "Sikandarabad" },
            { "distcode": "421", "name": "Gwadar" },
            { "distcode": "422", "name": "Panjgur" },
            { "distcode": "423", "name": "Keich (Turbat)" },
            { "distcode": "431", "name": "Sohbatpur" },
            { "distcode": "432", "name": "Jaffarabad" },
            { "distcode": "433", "name": "Kachhi (Bolan)" },
            { "distcode": "434", "name": "Naseerabad" },
            { "distcode": "436", "name": "Jhal Magsi" },
            { "distcode": "441", "name": "Chagai" },
            { "distcode": "442", "name": "Pishin" },
            { "distcode": "443", "name": "Quetta" },
            { "distcode": "444", "name": "Killa Abdullah" },
            { "distcode": "445", "name": "Nushki" },
            { "distcode": "451", "name": "Dera Bugti" },
            { "distcode": "452", "name": "Kohlu" },
            { "distcode": "453", "name": "Sibi" },
            { "distcode": "454", "name": "Ziarat" },
            { "distcode": "455", "name": "Harnai" },
            { "distcode": "461", "name": "Barkhan" },
            { "distcode": "462", "name": "Killa Saifullah" },
            { "distcode": "463", "name": "Loralai" },
            { "distcode": "464", "name": "Musa Khail" },
            { "distcode": "465", "name": "Zhob" },
            { "distcode": "466", "name": "Sherani" },
            { "distcode": "467", "name": "Dukki" }
        ],
        GilgitBaltistan: [
            { "distcode": "601", "name": "Skardu" },
            { "distcode": "602", "name": "Diamer" },
            { "distcode": "603", "name": "Ganche" },
            { "distcode": "604", "name": "Ghizer" },
            { "distcode": "605", "name": "Gilgit" },
            { "distcode": "606", "name": "Astore" },
            { "distcode": "607", "name": "Hunza" },
            { "distcode": "608", "name": "Kharmang" },
            { "distcode": "609", "name": "Nagar" },
            { "distcode": "610", "name": "Shigar" }
        ],
        AJK: [
            { "distcode": "", "name": "All Districts" },
            { "distcode": "511", "name": "Bhimber" },
            { "distcode": "512", "name": "Kotli" },
            { "distcode": "513", "name": "Mirpur" },
            { "distcode": "521", "name": "Jhelum Vellay" },
            { "distcode": "522", "name": "Muzaffarabad" },
            { "distcode": "523", "name": "Neelum" },
            { "distcode": "531", "name": "Bagh" },
            { "distcode": "532", "name": "Haveli" },
            { "distcode": "533", "name": "Poonch" },
            { "distcode": "534", "name": "Sudhnooti" }
        ],
        Punjab: [
            { "distcode": "", "name": "All Districts" },
            { "distcode": "111", "name": "Bahawalnagar" },
            { "distcode": "112", "name": "Bahawalpur" },
            { "distcode": "113", "name": "Rahimyar Khan" },
            { "distcode": "121", "name": "D.G Khan" },
            { "distcode": "122", "name": "Layyah" },
            { "distcode": "123", "name": "Muzaffargarh" },
            { "distcode": "124", "name": "Rajanpur" },
            { "distcode": "131", "name": "Faisalabad" },
            { "distcode": "132", "name": "Jhang" },
            { "distcode": "133", "name": "Toba Tek Singh" },
            { "distcode": "134", "name": "Chiniot" },
            { "distcode": "141", "name": "Gujranwala" },
            { "distcode": "142", "name": "Gujrat" },
            { "distcode": "143", "name": "Narowal" },
            { "distcode": "144", "name": "Sialkot" },
            { "distcode": "145", "name": "Hafizabad" },
            { "distcode": "146", "name": "Mandi Bahauddin" },
            { "distcode": "151", "name": "Kasur" },
            { "distcode": "152", "name": "Lahore" },
            { "distcode": "153", "name": "Okara" },
            { "distcode": "154", "name": "Sheikhupura" },
            { "distcode": "155", "name": "Nankana Sahib" },
            { "distcode": "161", "name": "Khanewal" },
            { "distcode": "162", "name": "Lodhran" },
            { "distcode": "163", "name": "Multan" },
            { "distcode": "164", "name": "Pakpattan" },
            { "distcode": "165", "name": "Sahiwal" },
            { "distcode": "166", "name": "Vehari" },
            { "distcode": "171", "name": "Attock" },
            { "distcode": "172", "name": "Chakwal" },
            { "distcode": "173", "name": "Jhelum" },
            { "distcode": "174", "name": "Rawalpindi" },
            { "distcode": "181", "name": "Bhakkar" },
            { "distcode": "182", "name": "Khushab" },
            { "distcode": "183", "name": "Mianwali" },
            { "distcode": "184", "name": "Sargodha" }
        ],
        KPK: [
            { "distcode": "", "name": "All Districts" },
            { "distcode": "311", "name": "Bannu" },
            { "distcode": "312", "name": "D.I. Khan" },
            { "distcode": "313", "name": "Lakki Marwat" },
            { "distcode": "316", "name": "Tank" },
            { "distcode": "321", "name": "Abbottabad" },
            { "distcode": "322", "name": "Haripur" },
            { "distcode": "323", "name": "Kohistan" },
            { "distcode": "324", "name": "Mansehra" },
            { "distcode": "325", "name": "Battagram" },
            { "distcode": "326", "name": "Tor Ghar" },
            { "distcode": "331", "name": "Karak" },
            { "distcode": "332", "name": "Kohat" },
            { "distcode": "335", "name": "Hangu" },
            { "distcode": "342", "name": "Buner" },
            { "distcode": "343", "name": "Chitral" },
            { "distcode": "344", "name": "Dir Lower" },
            { "distcode": "345", "name": "Malakand" },
            { "distcode": "346", "name": "Swat" },
            { "distcode": "347", "name": "Dir Upper" },
            { "distcode": "348", "name": "Shangla" },
            { "distcode": "351", "name": "Mardan" },
            { "distcode": "352", "name": "Swabi" },
            { "distcode": "361", "name": "Charsadda" },
            { "distcode": "364", "name": "Nowshera" },
            { "distcode": "365", "name": "Peshawar" }
        ],
        ICT: [
            { "distcode": "", "name": "All Districts" },
            { "distcode": "801", "name": "Bajaur" },
            { "distcode": "802", "name": "Mohmand" },
            { "distcode": "803", "name": "Khyber" },
            { "distcode": "804", "name": "Kurrum Upper" },
            { "distcode": "805", "name": "Orakzai" },
            { "distcode": "806", "name": "North Waziristan" },
            { "distcode": "807", "name": "South Waziristan" },
            { "distcode": "808", "name": "FR Peshawar" },
            { "distcode": "809", "name": "FR Kohat" },
            { "distcode": "810", "name": "FR Bannu" },
            { "distcode": "811", "name": "FR Lakki" },
            { "distcode": "812", "name": "FR D.I. Khan" },
            { "distcode": "813", "name": "FR Tank" },
            { "distcode": "814", "name": "Kurrum Lower - Central" }
        ],
        MAKP: [
            { "distcode": "", "name": "All Districts" },
            { "distcode": "801", "name": "Bajaur" },
            { "distcode": "802", "name": "Mohmand" },
            { "distcode": "803", "name": "Khyber" },
            { "distcode": "804", "name": "Kurrum Upper" },
            { "distcode": "805", "name": "Orakzai" },
            { "distcode": "806", "name": "North Waziristan" },
            { "distcode": "807", "name": "South Waziristan" },
            { "distcode": "808", "name": "FR Peshawar" },
            { "distcode": "809", "name": "FR Kohat" },
            { "distcode": "810", "name": "FR Bannu" },
            { "distcode": "811", "name": "FR Lakki" },
            { "distcode": "812", "name": "FR D.I. Khan" },
            { "distcode": "813", "name": "FR Tank" },
            { "distcode": "814", "name": "Kurrum Lower - Central" }
        ]
    };
    function getDefaultDistrictForProvince(province) {
        switch (province) {
            case '':
                return '';
            case 2:
                return '244';
            case 1:
                return '113';
            case 3:
                return '321';
            case 4:
                return '443';
            case 5:
                return '522';
            case 6:
                return '605';
            default:
                return '';
        }
    }
    function getProvinceNameFromCode(code) {
        switch (code) {
            case "":
                return 'all';
            case "1":
                return 'Punjab';
            case "2":
                return 'Sindh';
            case "3":
                return 'KPK';
            case "4":
                return 'Balochistan';
            case "5":
                return 'AJK';
            case "6":
                return 'GilgitBaltistan';
            case "7":
                return 'ICT';
            case "8":
                return 'MAKP';
            default:
                return 'Sindh';
        }
    }


    const selectedProvinceNew = getProvinceNameFromCode(selectedProvince);

    const [fpData, setFPData] = useState([]);
    const fetchFPData = (interval, dist, year, month, quarter) => {
        const params = {};

        if (interval) {
            params.interval = interval;
        }
        if (dist) {
            params.district = dist;
        }
        if (year) {
            params.year = year;
        }
        if (month) {
            params.month = month;
        }
        if (quarter) {
            params.quarter = quarter;
        }
    };

    useEffect(() => {
        onUrlChange(currentUrl);
        // console.log('setSelectedProvince', selectedProvince)
        setSelectedMonth(sessionStorage.getItem('month') || formattedMonth);
        setSelectedProvince(sessionStorage.getItem('province') || defaultProvince);
        setSelectedDistrict(sessionStorage.getItem('distcode') || defaultDistrict);
        const storedDashboardId = localStorage.getItem('dashboardid');
        setDashboardid(storedDashboardId || '1');

        sessionStorage.setItem('province', selectedProvince); // Save selected province
        sessionStorage.setItem('district', selectedDistrict);
    }, [currentUrl, dashboardid])


    const handleFormSubmit = (e) => {
        e.preventDefault();

        // if (!selectedDistrict || !selectedYear) {
        //     alert('Please select both District and Year.');
        //     return;
        // }
        // Create an object to store selected values
        const selectedValues = {
            procode: selectedProvince,
            distcode: selectedDistrict,
            year: selectedYear,
            month: selectedMonth,
            quarter: selectedQuarter,
            interval: selectedOption,
        };

        sessionStorage.setItem('procode', selectedProvince); // Save selected province
        sessionStorage.setItem('distcode', selectedDistrict);
        if (selectedOption == 'quarterly') {
            sessionStorage.setItem('filter', selectedYear + selectedQuarter);
            sessionStorage.setItem('selectedOption', 'fquarter');
            sessionStorage.setItem('year', selectedYear)
            sessionStorage.setItem('quarter', selectedQuarter)
        }
        if (selectedOption === 'annual') {
            sessionStorage.setItem('filter', selectedYear);
            sessionStorage.setItem('selectedOption', 'fyear');
            sessionStorage.setItem('year', selectedYear)
        }
        if (selectedOption === 'monthly') {
            sessionStorage.setItem('filter', selectedYear + selectedMonth);
            sessionStorage.setItem('selectedOption', 'fmonth');
            sessionStorage.setItem('year', selectedYear)
        }

        sessionStorage.setItem('month', selectedMonth);

        fetchFPData(selectedOption, selectedDistrict, selectedYear, selectedMonth, selectedQuarter);
        toggleClass();
    };

    return (
        <div className={isActive ? 'filter-div active' : 'filter-div'}>
            <h4>Filter</h4>
            <form onSubmit={handleFormSubmit}>
                <div className="form-floating mb-2">
                    <div className="mb-3 row">
                        <label htmlFor="Province" className="col-sm-3 col-form-label">Province</label>
                        <div className="col-sm-9">
                            <select
                                className="form-select"
                                value={selectedProvince}
                                onChange={(e) => setSelectedProvince(e.target.value)}
                                disabled
                            >
                                {provinceDistricts['AllProvince'].map((province, index) => (
                                    <option key={index} value={province.procode}>
                                        {province.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="District" className="col-sm-3 col-form-label">District</label>
                        <div className="col-sm-9">
                            <select
                                className="form-select"
                                value={selectedDistrict}
                                onChange={(e) => setSelectedDistrict(e.target.value)}>
                                {provinceDistricts[selectedProvinceNew].map((district, index) => (
                                    <option key={index} value={district.distcode}>
                                        {district.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="Year" className="col-sm-3 col-form-label">Year</label>
                        <div className="col-sm-9">
                            <select className="form-select" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} >
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="Month" className="col-sm-3 col-form-label">Month</label>
                        <div className="col-sm-9">
                            <select className="form-select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} disabled={selectedOption === "quarterly" || selectedOption === "annual"}>
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sep</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                            </select>
                        </div>
                    </div>
                    {currentUrl === '/actiondashboard' ? '' : <>
                        <div className="mb-3 row">
                            <label htmlFor="Month" className="col-sm-3 col-form-label">Quarter</label>
                            <div className="col-sm-9">
                                <select className="form-select" value={selectedQuarter} onChange={(e) => setSelectedQuarter(e.target.value)} disabled={selectedOption === "monthly" || selectedOption === "annual"}>
                                    <option value="1">Quarter 1</option>
                                    <option value="2">Quarter 2</option>
                                    <option value="3">Quarter 3</option>
                                    <option value="4">Quarter 4</option>
                                </select>
                            </div>
                        </div>

                        <div className="mb-3 row label-margin">
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        value="annual"
                                        checked={selectedOption === 'annual'}
                                        onChange={handleOptionChange}
                                    />
                                    Annual
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="quarterly"
                                        checked={selectedOption === 'quarterly'}
                                        onChange={handleOptionChange}
                                    />
                                    Quarterly
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="monthly"
                                        checked={selectedOption === 'monthly'}
                                        onChange={handleOptionChange}
                                    />
                                    Monthly
                                </label>
                            </div>
                        </div></>}
                </div>
                <div className='form-floating text-center'>
                    <input type='submit' value="Preview" className='btn btn-primary m-auto' onClick={handleFormSubmit} />
                </div>
            </form>
        </div>
    )
}

export default FilterComponent