import React, { useState } from 'react';

const AdminPanelTable = ({ data }) => {
  const [items, setItems] = useState(data);

  const handleRemovePage = (id) => {
    const confirmRemove = window.confirm("Are you sure you want to remove the page?");
    if (confirmRemove) {
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
      console.log(items);
    }
  };
  return (
    <div>
      <h1>Admin Panel Table</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Page</th>
            <th>Description</th>
            <th>Page Component</th>
            <th>Dashboard Icon</th>
            <th>URL Prefix</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.page}</td>
              <td>{item.description}</td>
              <td>{item.page_component}</td>
              <td>{item.dashboard_icon}</td>
              <td>{item.url_prefix}</td>
              <td>
                <button onClick={() => handleRemovePage(item.id)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPanelTable;
