import { faBell, faGem } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Circle1Component from '../circleProgressbar/Circle1Component';
import { faExclamationTriangle, faRing, faStarHalfStroke, faThumbsUp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const SingleActionComponent2 = ({ props }) => {
    const [showModal, setShowModal] = useState(false);

    const spanClass =
        parseInt(props.value, 10) > 70
            ? 'text-success'
            : parseInt(props.value, 10) > 40
                ? 'text-warning'
                : 'text-danger';

    const stringWithSpan = props.indicator_description.replace(
        /get_value/g,
        `<h2 class="figure-number ${spanClass}">${props.value}</h2>`
    );

    const handleModalToggle = () => {
        setShowModal(!showModal);
    };

    const customBorderTop = {
        borderTop: "8px solid"
    }
    const customStyles = {

        borderColor: parseInt(props.value, 10) > 70
            ? '#2ecc71'
            : parseInt(props.value, 10) > 40
                ? '#f1c40f'
                : '#e63b61',
    };

    const combinedStyles = { ...customBorderTop, ...customStyles };

    useEffect(() => {
        // Enable Bootstrap tooltips
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));
        return () => {
            // Cleanup tooltips on component unmount
            tooltipList.forEach((tooltip) => tooltip.dispose());
        };
    }, []); // Run this effect once when the component mounts

    return (
        <div className='accordion-body no-border' onClick={handleModalToggle} style={combinedStyles}>

            <div className='d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>

                <div>
                    <span className="icon-gem">
                        <FontAwesomeIcon icon={faBell} className={`progress-bar ${parseInt(props.value, 10) > 70
                            ? 'text-success'
                            : parseInt(props.value, 10) > 40
                                ? 'text-warning shake-warning'
                                : 'text-danger shake-danger'
                            }`} />
                    </span>
                    <p className="title pt-0" dangerouslySetInnerHTML={{ __html: stringWithSpan }}></p>
                </div>
                <div className="me-3 d-flex align-items-center">
                    {parseInt(props.value, 10) > 70 ? (
                        <FontAwesomeIcon icon={faThumbsUp} bounce title='Good' className='me-2' style={{ color: '#2ecc71' }} />
                    ) : parseInt(props.value, 10) > 40 ? (
                        <FontAwesomeIcon icon={faStarHalfStroke} title='Average' flip className='me-2' style={{ color: '#f1c40f' }} />
                    ) : (
                        <FontAwesomeIcon icon={faExclamationTriangle} beatFade title='Bad' className='me-2' style={{ color: '#e63b61' }} />
                    )}
                    <Circle1Component percent={props.value.replace('%', '')} colors={parseInt(props.value, 10) > 70
                        ? '#2ecc71'
                        : parseInt(props.value, 10) > 40
                            ? '#f1c40f'
                            : '#e63b61'} />
                </div>
            </div>

            {showModal && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header py-1">
                                <p className="modal-title"><span dangerouslySetInnerHTML={{ __html: stringWithSpan }}></span> - Details</p>
                                <button type="button" className="btn btn-danger close" data-dismiss="modal" aria-label="Close" onClick={handleModalToggle}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <p>{props.link}</p> */}
                                <iframe src={props.link} width="100%" height="500" frameborder="0" title={"title"}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
        // <div className='accordion-body' onClick={handleModalToggle} style={{
        //     borderColor: parseInt(props.value, 10) > 70
        //         ? '#acf56e'
        //         : parseInt(props.value, 10) > 40
        //             ? '#f5d86e'
        //             : '#f38b8b'
        // }}>
        //     <p className="title" dangerouslySetInnerHTML={{ __html: stringWithSpan }}></p>
        //     <div className="pb-1 mx-2">
        //         <div className="progress">
        //             <div
        //                 className={`progress-bar ${parseInt(props.value, 10) > 70
        //                     ? 'bg-success'
        //                     : parseInt(props.value, 10) > 40
        //                         ? 'bg-warning'
        //                         : 'bg-danger'
        //                     }`}
        //                 role="progressbar"
        //                 style={{ width: props.value }}
        //                 aria-valuenow={props.value}
        //                 aria-valuemin="0"
        //                 aria-valuemax="100"
        //             >
        //                 {props.value}
        //             </div>
        //         </div>
        //     </div>

        //     {showModal && (
        //         <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        //             <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        //                 <div className="modal-content">
        //                     <div className="modal-header py-1">
        //                         <p className="modal-title"><span dangerouslySetInnerHTML={{ __html: stringWithSpan }}></span> - Details</p>
        //                         <button type="button" className="btn btn-danger close" data-dismiss="modal" aria-label="Close" onClick={handleModalToggle}>
        //                             <span aria-hidden="true">&times;</span>
        //                         </button>
        //                     </div>
        //                     <div className="modal-body">
        //                         {/* <p>{props.link}</p> */}
        //                         <iframe src={props.link} width="100%" height="500" frameborder="0" title={"title"}></iframe>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     )}
        //     {showModal && <div className="modal-backdrop fade show"></div>}
        // </div>
    );
};

export default SingleActionComponent2;
